import React, { Component } from 'react'
import translateString from '../utils/Locales';
import '../pages/landingPage/style.css';
import { WaveLoading } from 'react-loadingg';
import Cookies from 'universal-cookie';
import loginStudente from '../services/loginStudente';

let t = translateString;
const cookies = new Cookies();

export default class LoginStudentiWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '', 
            password: '',
            error: false,
            errorMessage: '',
            isLoading: false,
        }; 
    }

    async handleLogin() {
        if (!this.state.username || !this.state.password) {
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.setState({ isLoading: true, error: false, errorMessage: '' });

        let res = await loginStudente(this.state.username, this.state.password);
        if (res.user !== null) {
            
            cookies.set('username', this.state.username);
            cookies.set('token', res.token);
            cookies.set('user-type', 'studente');
            
            window.location.href = '/#/studente/dashboard';
            return;
        }

        if (res.descrizione.includes('Credenziali non valide.')) {
            
            this.setState({ isLoading: false, error: true, errorMessage: 'Credenziali errate' });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (res.descrizione.includes('Username occupato')) {

            this.setState({ sLoading: false, error: true, errorMessage: 'Le credenziali sono corrette, ma il nome utente è già stato preso. Dovrai creare un nuovo account cliccando qui sopra.' });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 10000);
            return;
        }

        if (res.descrizione.includes('Nuovo utente')) {

            cookies.set('new_username', this.state.username);
            cookies.set('new_password', this.state.password);
            window.location.href = '/#/main/registrazione';
            return;
        }
        
    }

  render() {
    return (
        <div style={background} onClick={() => (this.props.onClose && !this.state.isLoading) && this.props.onClose()}>
            <div className='login-view' onClick={(e) => e.stopPropagation()}>
                { this.state.isLoading ? <WaveLoading /> :(
                    <div>
                        <h1 style={{ color: '#727272', fontSize: 30, marginTop: 10 }}>{t('login_stud')}</h1>
                        <p style={{ color: '#727272', marginTop: -10 }}>{t('esegui_log_stud')}<span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => window.location.href = '/#/main/registrazione'}>{t('registrati_qui')}</span></p>
                        <br/>
                        { this.state.error && <p style={{ color: 'red', fontWeight: 'bold' }}>{this.state.errorMessage}</p> }
                        <input style={loginInput} placeholder={'Username'} type={'text'} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                        <input style={{ ...loginInput, marginTop: 5 }} placeholder={'Password'} type={'password'} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                        <br/>
                        <div className="btn btn-primary" style={{ marginTop: 40, zIndex: 2 }} onClick={() => this.handleLogin()}>{t('accedi')}</div>
                    </div> 
                )} 
            </div>
        </div>
    )
  }
}

const background = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    bottom: 0, 
    zIndex: 3000,
    height: '100vh', 
    width: '100vw',
    backgroundColor: '#000000B3',
}

const loginInput = {
    width: '80%', 
    height: '50px', 
    backgroundColor: 'white', 
    color: '#727272', 
    borderWidth: '0px',
    borderBottomWidth: '1px', 
    borderBottomColor: '#727272',
}
