import React, { forwardRef, useImperativeHandle, useState } from 'react';
import arrayShuffle from 'array-shuffle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const RiorganizzazioneConcettuale = forwardRef((props, ref) => {

    const [risposte, setRisposte] = useState(arrayShuffle(props.risposte));

    useImperativeHandle(ref, () => ({
        getRisposta() {
            var risp = [];
            risposte.forEach((r) => {
                risp.push(r.id);
            });
            
            return risp;
        },
    }));

    function reorderRisposte(result) {
        
        var ord = risposte;
        var value = risposte.find((r) => r.id === parseInt(result.draggableId));

        ord.forEach((r, ind) => {
            if (r.id === parseInt(result.draggableId)) {
                ord.splice(ind, 1);
            }
        });

        ord.splice(result.destination.index, 0, value);
        setRisposte(ord);
    }

    return (
        <section className='question-container'>
            <p style={{ fontWeight: 'bold', color: 'grey' }}>Leggi il testo proposto e, successivamente, riordina gli avvenimenti trascinandoli secondo quanto letto.</p>
            <p style={{ marginTop: 30 }}>{props.testo}</p>
            <DragDropContext onDragEnd={reorderRisposte}>
                <div style={{ marginTop: 40 }}>
                    <Droppable droppableId="sections">
                        {(provided) => (
                            <ul style={{ listStyle: 'none' }} {...provided.droppableProps} ref={provided.innerRef}>
                                { risposte.map((r, ind) => {

                                    return(
                                        <Draggable key={r.id} draggableId={String(r.id)} index={ind}>
                                            { (provided) => (
                                                <li className='order-container' {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps}>
                                                    <p>{r.risposta}</p>
                                                </li>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </section>
    )
})

export default RiorganizzazioneConcettuale;