import React from "react";

export default class Constants extends React.Component {

    static apisFolder = '/apis/'; 

    static ipAddress = 'assessment-cpia.it';
    static mainUrl = 'assessment-cpia.it' + Constants.apisFolder;
    static basicUrl = 'assessment-cpia.it/';
    static defaultProtocol = 'https://';
    static PdfTitleFontSize = 11;
    static PdfNormalFontSize = 10;
    static PdfFooterFontSize = 9; 

}

export function getServiceName() {
    return 'CPIA';
}