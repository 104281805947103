import React from 'react';
import { WaveLoading } from 'react-loadingg';
import Cookies from 'universal-cookie';
import getStudenteCorrente from '../services/getStudenteCorrente';
import getTestsStudenti from '../services/getTestsStudenti';
import { MdKeyboardArrowRight } from 'react-icons/md';
import getRisultatiStudente from '../services/getRisultatiStudente';
import moment from 'moment';

const cookies = new Cookies();

export default class DashboardStudente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            tests: [], 
            results: [],
        };
    }
    
    async componentDidMount() {

        let username = cookies.get('username');
        let token = cookies.get('token');
        this.studente = await getStudenteCorrente(username, token);
        console.log(this.studente);

        let tests = await getTestsStudenti(username, token);

        let risultati = await getRisultatiStudente(username, token);

        this.setState({ isLoading: false, tests, results: risultati });
    }

    iniziaTest(id, nome) {

        cookies.set('currentTest', id);
        cookies.set('currentDescrizione', nome);
        window.location.href = '/#/studente/inizia';
    }

    apriRisultato(id_test) {

        cookies.set('idTest', id_test);
        window.location.href = '/#/studente/risultato';
    }

    render() {

        if (this.state.isLoading) return <WaveLoading />;
        if (!this.studente) {
            window.location.href = '/#/main/home';
            return(<></>);
        }

        return(
            <div className='dashboard-container' style={{ marginBottom: 200 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <section style={{ width: '100%' }}>
                        <h1>Ciao, {this.studente.nome} {this.studente.cognome}</h1>
                        <p>Qui troverai tutti i possibili test da svolgere e i risultati ottenuti nei test precedenti. Buona fortuna!</p>
                    </section>
                </div>

                <p style={{ color: 'grey', fontWeight: 'bold', fontSize: 20 }}>Svolgi un nuovo test</p>
                { this.state.tests.length === 0 && (
                    <div style={{ marginTop: -50, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 40, width: '100%' }}>
                        <img src={ require('../images/no_tests.png') } style={{ height: 100, width: 100, opacity: 0.6 }} />
                        <h6 style={{ color: 'grey', fontWeight: 'bold', opacity: 0.7, textAlign: 'center', width: '100%', marginTop: 30 }}>Nessun test da svolgere trovato</h6>
                    </div>
                )}
                { this.state.tests.map((t) => {

                    return(
                        <section key={t.id} className='test-btn' onClick={() => this.iniziaTest(t.id, t.descrizione)}>
                            <h5>{t.descrizione}</h5>
                            <section className='arrow-btn'>
                                <p>Inizia test</p>
                                <MdKeyboardArrowRight color="#258299" style={{ marginBottom: 10 }} />
                            </section>
                        </section>
                    );
                })}

                <p style={{ color: 'grey', fontWeight: 'bold', fontSize: 20, marginTop: this.state.tests.length > 0 ? 40 : 0 }}>I tuoi risultati</p>
                { this.state.results.length === 0 && (
                    <div style={{ marginTop: -50, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 40, width: '100%' }}>
                        <img src={ require('../images/no_results.png') } style={{ height: 100, width: 100, opacity: 0.6 }} />
                        <h6 style={{ color: 'grey', fontWeight: 'bold', opacity: 0.7, textAlign: 'center', width: '100%' }}>Non hai ancora svolto nessun test</h6>
                    </div>
                )}
                { this.state.results.map((risultato) => {

                    return(
                        <section key={risultato.id} className='test-btn' onClick={() => this.apriRisultato(risultato.id)}>
                            <section>
                                <h5>{risultato.descrizione}</h5>
                                <p style={{ color: 'grey', fontSize: 12, marginTop: -5 }}>Eseguito il {moment(risultato.iniziato, 'yyyy-MM-DD HH:mm').format('DD/MM/yyyy')} alle {moment(risultato.iniziato, 'yyyy-MM-DD HH:mm').format('HH:mm')}</p>
                            </section>
                            <section>
                                <p style={{ width: '100%', textAlign: 'right', fontSize: 18, fontWeight: 'bold', marginBottom: -5, marginRight: 5, color: parseInt(risultato.esito) > 11 ? 'green' : 'red' }}>{risultato.esito} / 20</p>
                                <section className='arrow-btn'>
                                    <p>Apri esito</p>
                                    <MdKeyboardArrowRight color="#258299" style={{ marginBottom: 10 }} />
                                </section>
                            </section>
                        </section>
                    );
                })}
            </div>
        );
    }
}