import React from 'react';
import TextInput from '../components/TextInput';

export default class NuovoCompletamento extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domanda: '', 
            risposte: [
                { id: 1, risposta: '' }, 
                { id: 2, risposta: '' },
                { id: 3, risposta: '' }
            ], 
            risposta_corretta: undefined, 
            trash: false, 
            deleted: false, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' };

        var allRisposte = true;
        this.state.risposte.forEach((r) => {
            if (!r.risposta) allRisposte = false;
        });

        if (this.state.domanda && this.state.risposta_corretta && allRisposte) {
            
            this.setState({ error: false });

            var res = {
                tipo: 8, 
                domanda: this.state.domanda,
                risposta: this.state.risposta_corretta, 
                risposte: this.state.risposte
            }
            return res;
        }

        this.setState({ error: true });
    }

    delete() {
        this.setState({ deleted: true });
    }

    setRisposta(id, text) {
        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.risposta = text;
            }
        });

        this.setState({ risposte });
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <TextInput placeholder={'Prima parte dell\'affermazione'} value={this.state.domanda} fontSize={20} onChange={(text) => this.setState({ domanda: text })} />
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold' }}>Opzioni (inserire le tre opzioni di completamento. Segna poi quella corretta):</p>
                <div className='answer-container' style={{ flexDirection: 'column' }}>
                    { this.state.risposte.map((risposta) => {

                        return(
                            <section key={risposta.id} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <img src={ require('../images/tick.png') } style={{ width: 20, height: 20, cursor: 'pointer', opacity: this.state.risposta_corretta === risposta.id ? 1.0 : 0.3 }} onClick={() => this.setState({ risposta_corretta: risposta.id })} />
                                <TextInput style={{ marginLeft: 15 }} placeholder={'Opzione'} value={risposta.risposta} fontSize={14} onChange={(text) => this.setRisposta(risposta.id, text)} />
                            </section>
                        );
                    })}
                </div>
                <img src={ require('../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.setState({ deleted: true });
                    }
                }} />
            </section>
        );
    }
}