import React from 'react';
import Cookies from 'universal-cookie';

import '../index.css';

const cookies = new Cookies();

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
        };
    }

    handleResize() {
        if (window.innerWidth < 992) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize());
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.handleResize());
    }

    render() {

        return(
            <nav id="navbar" className="navbar is-sticky navbar-expand-lg fixed-top">
                <div className="container">
                    <img alt="" className='navbar-brand' style={{width: 'auto', height: 80, cursor: 'pointer'}} onClick={() => window.location.href= window.location.href.includes('studente') ? '/#/studente/dashboard' : window.location.href.includes('app') ? '/#/app/tests' : window.location.href.includes('admin') ? '/#/admin/dashboard' : '/#/main/home'} src={require('../images/icon.png')}/>
                    <h1 style={{ color: '#ff4e57', fontFamily: 'Just Another Hand', fontSize: 50, marginTop: 15 }}></h1>
                    <div className="collapse navbar-collapse" id="navbarNav">

                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link page-scroll" style={{cursor: 'pointer'}} onClick={() => window.location.href='/#/main/home'}>Home</a>
                            </li>
                            { ((this.props.tipo === "amministratore" && cookies.get('ad_username')) || (["studente", "insegnante"].includes(this.props.tipo) && cookies.get('username'))) && (
                                <li className="nav-item" style={{ marginLeft: 10 }}>
                                    <p style={{ color: 'red', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => {

                                        if (this.props.tipo === 'studente' || this.props.tipo === "insegnante") {
                                            cookies.remove('username');
                                            cookies.remove('token');
                                            window.location.href = "/#/main/home";
                                        } else if (this.props.tipo === "amministratore") {
                                            cookies.remove('ad_username');
                                            cookies.remove('ad_token');
                                            window.location.href = "/#/admin/login";
                                        }
                                    }}>Logout</p>
                                </li>
                            )}
                        </ul>

                    </div>
                </div>
            </nav>
        );

    }
}