import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import arrayShuffle from 'array-shuffle';

export default class AudioSceltaMultipla extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domanda: props.domanda, 
            risposte: arrayShuffle(props.risposte), 
            audio: props.audio,
            risposta: 0, 
        };
    }

    getRisposta() {
        return this.state.risposta;
    }

    render() {

        return(
            <section className='question-container'>
                <p style={{ color: '#818181', marginTop: 10, fontWeight: 'bold' }}>Clicca sulla risposta che completa l'affermazione qui sotto.</p>
                <section className='answer answer-selectable' style={{ marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, marginLeft: '10%' }}>
                    <ReactAudioPlayer style={{ marginTop: '10px' }} src={this.state.audio} controls />
                    <h5 style={{ marginTop: 30 }}>{this.state.domanda}</h5>
                </section>
                <div className='answer-container' style={{ flexDirection: 'column', paddingTop: 30 }}>
                    { this.state.risposte.map((risposta) => {

                        return(
                            <section key={parseInt(risposta.id)} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }} onClick={() => this.setState({ risposta: parseInt(risposta.id)})}>
                                { this.state.risposta === parseInt(risposta.id) && <img src={ require('../images/tick.png') } style={{ width: 20, height: 20, cursor: 'pointer' }} /> }
                                <span style={{ marginLeft: this.state.risposta === parseInt(risposta.id) ? 10 : 30 }}>{risposta.risposta}</span>
                            </section>
                        );
                    })}
                </div>
            </section>
        );
    }
}