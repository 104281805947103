import React from 'react';
import Cookies from 'universal-cookie';
import getCurrentUser from '../services/getCurrentUser';
import { WaveLoading } from 'react-loadingg';
import Button from '../components/Button';
import getStatisticheInsegnante from '../services/getStatisticheInsegnante';
import getTests from '../services/getTests';
import { PieChart } from 'react-minimal-pie-chart';

const cookies = new Cookies();

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            stats: [],
        };
    }

    async componentDidMount() {

        let username = cookies.get('username');
        let token = cookies.get('token');

        this.utente = await getCurrentUser(username, token);
        if (!this.utente) {
            window.location.href = '/#/main/home';
            return;
        }

        let stats = await getStatisticheInsegnante(username, token);
        let tests = await getTests();
        var types = [];

        tests.forEach((t) => {
            types.push({ id: t.id, test: t.descrizione, count: 0 });
        });

        stats.forEach((s) => {
            types.forEach((t) => {
                if (parseInt(s.test) === parseInt(t.id)) {
                    t.count = t.count + 1;
                }
            }); 
        });

        console.log(types);

        this.setState({ isLoading: false, stats, types });
    }

    render() {

        if (this.state.isLoading) return <WaveLoading />;

        return(
            <div className='dashboard-container' style={{ marginBottom: 200 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <section style={{ width: '80%' }}>
                        <h1>Ciao, {this.utente.username}</h1>
                        <p>Qui potrai trovare alcune statistiche riguardo alle domande che hai creato. Clicca sul pulsante qui a fianco per aggiungerne delle nuove. </p>
                    </section>
                    <section style={{ marginLeft: 30 }}>
                        <Button button={'NUOVE DOMANDE'} onClick={ () => window.location.href = '/#/app/nuovo' } />
                    </section>
                </div>

                { this.state.stats.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 40, width: '100%' }}>
                        <img src={ require('../images/nothing.jpg') } style={{ height: 200, width: 200, opacity: 0.8 }} />
                        <h4 style={{ color: 'grey', fontWeight: 'bold', opacity: 0.7, textAlign: 'center', width: '100%' }}>Non hai ancora creato nessuna domanda</h4>
                    </div>
                )}
                { this.state.stats.length > 0 && (
                    this.state.types.map((t) => {

                        let stats = this.state.stats.filter((s) => parseInt(s.test) === parseInt(t.id));
                        var fatte = 0;
                        var corrette = 0;

                        stats.forEach((s) => {
                            fatte += parseInt(s.fatta);
                            corrette += parseInt(s.corretta);
                        });

                        return(
                            <div key={t.id} className='stats-container'>
                                <section>
                                    <h5>{t.test}</h5>
                                    <p>Hai creato <span style={{ fontWeight: 'bold' }}>{t.count}</span> {t.count === 1 ? 'domanda' : 'domande'} per questa tipologia di test.</p>
                                    { t.count > 0 && (<p style={{ marginRight: 100, marginTop: -10 }}>Il grafico a fianco mostra i risultati cumulativi. Le tue domande sono state presentate <span style={{ fontWeight: 'bold' }}>{ parseInt(fatte) }</span>  { fatte === 1 ? 'volta' : 'volte' } agli studenti. Abbiamo registrato una risposta corretta <span style={{ fontWeight: 'bold', color: 'green' }}>{ parseInt(corrette) }</span> { corrette === 1 ? 'volta' : 'volte' }</p> )}
                                </section>
                                { (t.count > 0 && fatte > 0) && (
                                    <PieChart
                                        data={[
                                            { title: 'Errate', value: fatte - corrette, color: '#DF6449' }, 
                                            { title: 'Corrette', value: corrette, color: '#7ED58A' }
                                        ]}
                                        totalValue={fatte}
                                        style={{ width: 120, height: 120, marginRight: 40 }}
                                        segmentsShift={5}
                                        radius={45}
                                    />
                                )}
                            </div>
                        );
                    })
                )}
            </div>
            
        );
    }
}