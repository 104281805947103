import React from 'react';

import '../index.css';

export default class Button extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {

        return(
            <div className='btn-container' style={{ position: 'relative' }}>
                <div className='main-btn' style={{ backgroundColor: this.props.color ? this.props.color : '#007bff' }} onClick={() => this.handleClick()}>
                    {this.props.button}
                </div>
                { (this.props.notifiche && this.props.notifiche > 0) && (
                    <div style={{ position: 'absolute', top: -5, right: -10, backgroundColor: 'red', width: 20, height: 20, borderRadius: 10, color: 'white', fontWeight: 'bold', boxShadow: '0px 2px 5px black', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11 }}>
                        {this.props.notifiche}
                    </div>
                )}
            </div>
        );
    }
}