import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { withStyles } from '@material-ui/styles';

import LandingPage from "../../pages/landingPage/landingPage";
import VerifyEmail from "../../pages/landingPage/VerifyEmail";
import ComingSoonPage from "../../pages/landingPage/comingSoon";
import RegistrazioneStudente from "../../pages/landingPage/RegistrazioneStudente";
import RegistrazioneInsegnante from "../../pages/landingPage/RegistrazioneInsegnante";

const makeStyles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  }
});

class MainLayout extends React.Component {

  constructor(props){
    super(props)
    this.state = { }
  }

  async componentDidMount(){

  }


  render (){

    // global
    const { classes } = this.props;

    return (
      <div className={classes.root}>
          <>
              <Switch>
                <Route path='/main/working' component={ComingSoonPage} />
                <Route path='/main/home' component={LandingPage} />
                <Route path='/main/registrazione' component={RegistrazioneStudente} />
                <Route path='/main/insegnante/registrazione' component={RegistrazioneInsegnante} />
                <Route path='/main/verify' component={VerifyEmail} />
              </Switch>
          </>
      </div>
    );
  }
  
}

export default withStyles(makeStyles)(withRouter(MainLayout));
