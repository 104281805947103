import React from 'react';
import TextInput from '../components/TextInput';

export default class NuovaRicostruzione extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testo: '', 
            risposte: [
                { id: 1, risposta: '' }, 
                { id: 2, risposta: '' },
                { id: 3, risposta: '' },
            ], 
            trash: false, 
            deleted: false, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' };

        var allRisposte = true;
        let risposte = []
        var count = 0;
        var i = 1;

        this.state.risposte.forEach((r) => {
            if (r.risposta.trim() !== '') {
                let risp = { id: i, risposta: r.risposta };
                risposte.push(risp);
                i++;
                count++;
            }
        });

        if (count === 0) allRisposte = false;

        if (this.state.testo && allRisposte) {
            
            this.setState({ error: false });

            var res = {
                tipo: 7, 
                domanda: this.state.testo, 
                risposte: risposte
            }
            return res;
        }

        this.setState({ error: true });
    }

    delete() {
        this.setState({ deleted: true });
    }

    setRisposta(id, text) {
        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.risposta = text;
            }
        });

        this.setState({ risposte });
    }

    addRisposta() {
        let risposte = this.state.risposte;
        risposte.push({ id: this.state.risposte.length + 1, risposta: '' });
        this.setState({ risposte });
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <textarea style={{ resize: 'none', height: 150, width: '100%', border: '1px solid #F3F3F3', marginRight: -1, padding: 20, borderRadius: 10, border: '1px solid lightgrey' }} placeholder={'Inserisci qui il testo'} onChange={(e) => this.setState({ testo: e.target.value })} />
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold' }}>Risposte (nell'ordine corretto):</p>
                <div className='answer-container' style={{ flexDirection: 'column' }}>
                    { this.state.risposte.map((risposta) => {

                        return(
                            <section key={risposta.id} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <TextInput placeholder={'Risposta'} value={risposta.risposta} fontSize={14} onChange={(text) => this.setRisposta(risposta.id, text)} />
                            </section>
                        );
                    })}
                    { this.state.risposte.length < 10 && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer', marginTop: 20 }} onClick={() => this.addRisposta()}>
                            <img src={ require('../images/add.png') } style={{ width: 20, height: 20 }} />
                            <span style={{ marginLeft: 10 }}>Aggiungi nuova sezione</span>
                        </div>
                    )}
                    { this.state.risposte.length >= 10  && (
                        <span style={{ marginLeft: 10, marginTop: 20, color: 'grey' }}>Hai raggiunto il limite di 10 sezioni per questa domanda.</span>
                    )}
                </div>
                <img src={ require('../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.setState({ deleted: true });
                    }
                }} />
            </section>
        );
    }
}