import React from "react";
import Cookies from "universal-cookie";
import SelectInput from "./SelectInput";
import { domande } from '../utils/tipologie';
import getTests from "../services/getTests";
import filtraDomande from "../services/filtraDomande";

const cookies = new Cookies();

export default class FilterDomandeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.tipologie = domande;


        this.state = {
            tipologia: { value: null, label: 'Tipologia di domanda' },
            test: { value: null, label: 'Tipo di test' },
            isLoading: true, 
        }
    }

    async componentDidMount() {

        this.tests = await getTests();

        this.tests.forEach((test, ind) => {
            this.tests[ind] = { value: test.id, label: test.descrizione };
        });
        this.setState({ isLoading: false });
    }

    async reload() {
        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token')
        
        let res = await filtraDomande(username, token, this.state.test.value, this.state.tipologia.value);
        this.props.onContent(res, parseInt(this.state.tipologia.value));
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ padding: 15, borderRadius: 10, marginTop: 30, backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                <SelectInput
                    value={this.state.test}
                    hidable={false}
                    isMulti={false}
                    questions={true}
                    options={this.tests} 
                    onChange={async (option) => {
                        this.setState({ test: option });
                        
                        let username = cookies.get('ad_username');
                        let token = cookies.get('ad_token')
                        
                        let res = await filtraDomande(username, token, option.value, this.state.tipologia.value);

                        if (this.props.onContent) {
                            this.props.onContent(res, this.state.tipologia.value);
                        }
                    }}
                />
                <section style={{ height: 10, width: 20 }} />
                <SelectInput
                    value={this.state.tipologia}
                    hidable={false}
                    isMulti={false}
                    questions={true}
                    options={this.tipologie} 
                    onChange={ async (option) => {
                        this.setState({ tipologia: option });

                        let username = cookies.get('ad_username');
                        let token = cookies.get('ad_token')
                        let res = await filtraDomande(username, token, this.state.test.value, option.value);

                        if (this.props.onContent) {
                            this.props.onContent(res, option.value);
                        }
                    }} 
                />
            </div>
        );
    }
}
