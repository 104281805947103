import React from 'react';
import Cookies from 'universal-cookie';
import { TbNumbers } from 'react-icons/tb'; 
import { MdAccessTimeFilled, MdOutlineGrade } from 'react-icons/md';
import { VscTypeHierarchySub } from 'react-icons/vsc';
import Button from '../components/Button';
import iniziaTest from '../services/iniziaTest';

const cookies = new Cookies();

export default class InfoTest extends React.Component {
    constructor(props) {
        super(props);

        let test = cookies.get('currentTest');
        let nome = cookies.get('currentDescrizione');

        if (!test || !nome) {
            cookies.remove('currentTest');
            cookies.remove('currentDescrizione');

            window.location.href = '/#/studente/dashboard';
            return;
        }

        this.state = {
            id_test: test, 
            nome_test: nome,
        };
    } 

    render() {

        return(
            <div className='dashboard-container' style={{ marginBottom: 200 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <section style={{ width: '100%' }}>
                        <h1>{this.state.nome_test}</h1>
                        <p>Stai per iniziare un nuovo test. Ecco alcune informazioni utili che dovrai leggere attentamente prima di iniziare.</p>
                    </section>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <section style={{ width: '100%' }}>
                        <div className='info-div'>
                            <TbNumbers size={25} style={{ marginTop: -15 }} />
                            <p style={{ maxWidth: '80%' }}>Il test è composto da 20 domande a risposta chiusa;</p>
                        </div>
                        <div className='info-div'>
                            <MdAccessTimeFilled size={25} style={{ marginTop: -15 }} />
                            <p style={{ maxWidth: '80%' }}>Per completare tutte le risposte avrai a disposizione un tempo di 20 minuti, oltre il quale le risposte che hai dato saranno automaticamente consegnate e corrette;</p>
                        </div>
                        <div className='info-div'>
                            <VscTypeHierarchySub size={25} style={{ marginTop: -15 }} />
                            <p style={{ maxWidth: '80%' }}>Le domande possono essere di queste tipologie:</p>
                        </div>
                        <p style={{ marginLeft: 45 }}><b>Vero/Falso</b>: per rispondere, clicca sulla risposta che ritiene corretta;</p>
                        <p style={{ marginLeft: 45 }}><b>Risposta multipla</b>: dovrai scegliere tra 4 possibili risposte. Clicca su quella che ritieni corretta;</p>
                        <p style={{ marginLeft: 45 }}><b>Abbinamento testo/testo</b>: ti verranno mostrati diversi testi da leggere e, sotto di essi, alcuni testi da abbinarci. Trascina nella casella corrispondente il testo che ritieni corretto;</p>
                        <p style={{ marginLeft: 45 }}><b>Abbinamento immagini/testo</b>: ti verranno mostrati diversi testi da leggere e, sotto di essi, alcune immagini da abbinarci. Trascina nella casella corrispondente l'immagine che ritieni corretta;</p>
                        <p style={{ marginLeft: 45 }}><b>Ascolto di un brano</b>: ti verrà richiesto di ascoltare un brano audio e di rispondere poi a una domanda con una risposta di tipo "Vero/Falso" oppure a risposta multipla;</p>
                        <p style={{ marginLeft: 45 }}><b>Cloze</b>: ti verrà mostrato un testo in cui sono state rimosse alcune parole. Trascina le parole all'interno dei relativi spazi;</p>
                        <p style={{ marginLeft: 45 }}><b>Sequenza concettuale</b>: ti verrà mostrato un testo da leggere e, sotto di esso, alcune affermazioni. Trascina le affermazioni in modo da riordinarle secondo il testo;</p>
                        <p style={{ marginLeft: 45 }}><b>Completamento di un'affermazione</b>: ti verrà mostrata una frase non terminata. Seleziona il completamento corretto.</p>
                        <div className='info-div' style={{ marginTop: 30 }}>
                            <MdOutlineGrade size={25} style={{ marginTop: -15 }} />
                            <p style={{ maxWidth: '80%' }}>Il test si considera superato se risponderai correttamente almeno al 60% delle domande</p>
                        </div>
                    </section>
                    <section className='info-img' style={{ width: '100%' }}>
                        <img src={ require('../images/info_test.png') } style={{ width: '80%', height: 'auto', opacity: 0.7 }} alt="Avviso" />
                    </section>
                </div>
                <div style={{ width: '50%', marginLeft: '25%' }}>
                    <Button button="Inizia il test" onClick={ this.state.isLoading ? () => {} : async () => {

                        this.setState({ isLoading: true });
                        
                        let username = cookies.get('username');
                        let token = cookies.get('token');
                        let res = await iniziaTest(username, token, this.state.id_test);
                        if (!res || res.result === 'KO') {
                            alert('Impossibile iniziare il test al momento. Riprova più tardi.');
                            return;
                        }

                        cookies.set('idTest', res.id);
                        window.location.href = '/#/studente/test';
                    }} />
                </div>
            </div>
        );
    }
}