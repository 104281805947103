import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import addTest from '../services/addTest';
import changeVisibilitaTest from '../services/changeVisibilitaTest';
import deleteTest from '../services/deleteTest';
import getAllTests from '../services/getAllTests';
import getInsegnantiDaApprovare from '../services/getInsegnantiDaApprovare';
import Constants, { getServiceName } from '../utils/consts';

const cookies = new Cookies();

export default class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true, 
            codice: '',
            nome: '',
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Dashboard';
        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let tests = await getAllTests(username, token);
        let daApprovare = await getInsegnantiDaApprovare(username, token);
        console.log(daApprovare);

        this.setState({ isLoading: false, tests, daApprovare });
    }

    openPopup(url, windowname, w, h, x, y) {
        window.open(url, windowname, "resizable=no, toolbar=no, address=no, scrollbars=no, menubar=no, status=no, directories=no, width=" + w + ", height=" + h + ", left=" + x + ", top=" + y);
    }

    async cambiaVisibilita(test) {

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');

        if (test.visibile === '1' && window.confirm('Vuoi davvero nascondere il test: "' + test.descrizione + '" a insegnanti e alunni?')) {
            let res = await changeVisibilitaTest(username, token, test.id, 0);
            if (res.result === 'ok') {
                window.location.reload();
                return;
            }
        } else if (test.visibile === '0' && window.confirm('Vuoi davvero mostrare il test: "' + test.descrizione + '" a insegnanti e alunni?')) {
            let res = await changeVisibilitaTest(username, token, test.id, 1);
            if (res.result === 'ok') {
                window.location.reload();
                return;
            }
        }
    }

    async aggiungiTest() {
        
        if (!this.state.nome.trim()) {
            return;
        }

        if (window.confirm('Vuoi davvero creare il nuovo test: "' + this.state.nome.trim() + '"?')) {
            
            let username = cookies.get('ad_username');
            let token = cookies.get('ad_token');
            let res = await addTest(username, token, this.state.nome.trim());
            if (res.result === 'KO') {
                alert(res.descrizione);
                return;
            }

            window.location.reload();
        }
    }

    async deleteTest(test) {

        if (window.confirm('Vuoi davvero eliminare il test: "' + test.descrizione + '"? Tutte le domande, le risposte e i risultati ottenuti da questo test saranno rimossi. Se vuoi solo renderlo privato, utilizza la funzione "NASCONDI".')) {

            let username = cookies.get('ad_username');
            let token = cookies.get('ad_token');
            let res = await deleteTest(username, token, test.id);
            if (res.result === 'KO') {
                alert(res.descrizione);
                return;
            }

            window.location.reload();
        }
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <>
                <div className='dashboard-container' style={{ marginBottom: 200 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                        <section style={{ width: '100%' }}>
                            <h1>Dashboard</h1>
                            <p>Qui troverai tutte le funzionalità del pannello amministratore.</p>
                        </section>
                    </div>

                    <div className='stats-container' style={{ flexDirection: 'column' }}>
                        <h4 style={{ width: '100%' }}>Validazione risultato</h4>
                        <p style={{ width: '100%', textAlign: 'left', marginBottom: 20, marginTop: -5 }}>Inserisci il codice univoco presente sul PDF dello studente per verificare la correttezza del risultato.</p>
                        <section className='with-btn'>
                            <TextInput placeholder={'Codice test'} fontSize={14} width={'80%'} onChange={(codice) => this.setState({ codice })} />
                            <section>
                                <Button button={'VERIFICA'} onClick={() => this.state.codice.trim() && window.open(Constants.defaultProtocol + window.location.host + '/#/admin/risultato?id=' + this.state.codice.replace(/#/g, '').split('/')[0], '_blank')} />
                            </section>
                        </section>
                    </div>
                    
                    <div className='stats-container' style={{ flexDirection: 'column' }}>
                        <h4 style={{ width: '100%' }}>Tipologie di test</h4>
                        <p style={{ width: '100%', textAlign: 'left', marginBottom: 20, marginTop: -5, marginBottom: 40 }}>Qui puoi vedere tutte le tipologie di test che sono state create e puoi modificarne la visibilità per professori e studenti.</p>
                        { this.state.tests.length > 0 && (
                            <>
                                { this.state.tests.map((t) => {

                                    return(
                                        <section key={t.id} className='with-btn half with-background'>
                                            <h6>{t.descrizione}</h6>
                                            <section className='side-btn'>
                                                <div style={{ marginRight: 5 }}>
                                                    <Button button={t.visibile === '1' ? 'NASCONDI' : 'MOSTRA'} onClick={() => this.cambiaVisibilita(t)} />
                                                </div>
                                                <div style={{ marginLeft: 5 }}>
                                                    <Button color={'red'} button={'ELIMINA'} onClick={() => this.deleteTest(t)}/>
                                                </div>
                                            </section>
                                        </section>
                                    );
                                })}
                            </>
                        )}
                        <h4 style={{ width: '100%', marginTop: 40 }}>Aggiungi nuova tipologia</h4>
                        <p style={{ width: '100%', textAlign: 'left', marginBottom: 20, marginTop: -5, marginBottom: 40 }}>Inserisci un nome per la nuova tipologia di test da creare. Una volta confermata l'operazione il test sarà visibile agli insegnanti e agli studenti.</p>
                        <section className='with-btn'>
                            <TextInput placeholder={'Nome del nuovo test'} fontSize={14} width={'80%'} onChange={(nome) => this.setState({ nome })} />
                            <section>
                                <Button button={'AGGIUNGI'} onClick={() => this.aggiungiTest()} />
                            </section>
                        </section>
                    </div>

                    <div className='stats-container' style={{ flexDirection: 'column' }}>
                        <section className='with-btn'>
                            <section>
                                <h4 style={{ width: '100%' }}>Gestione insegnanti</h4>
                                <p style={{ width: '100%', textAlign: 'left', marginBottom: 20, marginTop: -5 }}>Gestisci gli insegnanti che partecipano alla creazione di nuove domande.</p>
                            </section>
                            <section>
                                <Button button={'APRI GESTIONE'} onClick={() => window.location.href = '/#/admin/insegnanti'} notifiche={ this.state.daApprovare ? this.state.daApprovare.length : 0 } />
                            </section>
                        </section>
                    </div>

                    <div className='stats-container' style={{ flexDirection: 'column' }}>
                        <section className='with-btn'>
                            <section>
                                <h4 style={{ width: '100%' }}>Gestione domande</h4>
                                <p style={{ width: '100%', textAlign: 'left', marginBottom: 20, marginTop: -5 }}>Gestisci tutte le domande inserite dagli insegnanti per ogni test.</p>
                            </section>
                            <section>
                                <Button button={'APRI GESTIONE'} onClick={() => window.location.href = '/#/admin/domande'} />
                            </section>
                        </section>
                    </div>

                    <div className='stats-container' style={{ flexDirection: 'column' }}>
                        <section className='with-btn'>
                            <section>
                                <h4 style={{ width: '100%' }}>Statistiche</h4>
                                <p style={{ width: '100%', textAlign: 'left', marginBottom: 20, marginTop: -5 }}>Genera statistiche sui risultati dei test e vedi lo stato di compilazione attuale di ogni test.</p>
                            </section>
                            <section>
                                <Button button={'APRI STATISTICHE'} onClick={() => window.location.href = '/#/admin/statistiche'} />
                            </section>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}