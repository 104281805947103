
import it from './it_strings.json';
import en from './en_strings.json';

 export default function translateString(id) {

    if (id) {
        const lang = window.sessionStorage.getItem('lang');
        const browserLang = navigator.language;
        
        if (lang === null || lang === '') {
            if (browserLang.includes('it')) {
                return JSON.stringify(it[id]).replace(/"/g, '');
            } else {
                return JSON.stringify(en[id]).replace(/"/g, '');
            } 
        } else if (lang === 'it') {
            return JSON.stringify(it[id]).replace(/"/g, '');
        } else {
            return JSON.stringify(en[id]).replace(/"/g, '');
        }
    }
}
