import React from 'react';
import Cookies from 'universal-cookie';
import deleteDomanda from '../../services/deleteDomanda';
import updateDomanda from '../../services/updateDomanda';

const cookies = new Cookies();

export default class ModificaCloze extends React.Component {
    constructor(props) {
        super(props);

        var text = props.domanda.domanda;
        let risposte = JSON.parse(props.domanda.risposte);

        var ind = 0;

        while(text.indexOf('%%!%%') !== -1 && ind < risposte.length) {
            text = text.replace('%%!%%', '%-' + risposte[ind].risposta + '-%');
            ind++;
        }

        this.state = {
            test: props.domanda.test,
            id: props.domanda.id, 
            testo: text, 
            trash: false, 
            deleted: false,
            selection: undefined, 
            error: false, 
        };
    }


    async save() {
        let d = this.getQuestion();
        if (!d) return;

        let obj = {
            tipo: 6, 
            domanda: d.domanda, 
            risposte: JSON.stringify(d.risposte),
            audio: '',
            allegato: null, 
        };

        let obj2 = {
            risposta_corretta: '',  
        };

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await updateDomanda(username, token, this.state.id, this.state.test, obj, obj2);

        if (res.result === 'ok') {
            alert('Salvataggio effettuato.');
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' }

        if (this.state.testo && this.state.testo.includes('%-')) {

            var parole = [];
            var text = this.state.testo;

            var ind = text.indexOf('%-');
            var count = 1;
            while(ind !== -1) {
            
                let sub = text.substring(ind + 2, text.indexOf('-%'));
                parole.push({ id: count, risposta: sub });
                count++;
                text = text.substring(text.indexOf('-%') + 2, text.length);
                ind = text.indexOf('%-');
            }

            var testo = this.state.testo;
            var cleanInd = testo.indexOf('%-');
            var count = 0;
            while(cleanInd !== -1 && count < 1000) {
            
                let sub = testo.substring(cleanInd, testo.indexOf('-%') + 2);
                testo = testo.replace(sub, '%%!%%');
                cleanInd = testo.indexOf('%-');
                count++;
            }

            this.setState({ error: false });
            
            var res = {
                tipo: 6, 
                domanda: testo,
                risposte: parole,
            }
            return res;
        }

        this.setState({ error: true });
    }

    async delete() {
        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await deleteDomanda(username, token, this.state.id);

        if (res.result === 'ok') {
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    addBuco() {

        if (this.state.selection) {

            let cursorPosition = this.state.selection
            let textBeforeCursorPosition = this.state.testo.substring(0, cursorPosition)
            let textAfterCursorPosition = this.state.testo.substring(cursorPosition, this.state.testo.length)
            this.setState({ testo: textBeforeCursorPosition + '%-parola qui-%' + textAfterCursorPosition });
        }
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold' }}>Inserisci un testo e, dove vuoi inserire una parola vuota, clicca il pulsante qui sotto. Inserisci poi la parola corretta dove leggi "parola qui". (Ad es, se voglio che lo studente selezioni la parola "esempio": %-parola qui-% diventa %-esempio-%).</p>
                <div style={{ backgroundColor: 'white', boxShadow: '0px 2px 10px lightgrey', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: 'darkgrey', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 10, borderRadius: 10, width: 100, cursor: 'pointer' }} onClick={() => this.addBuco()}>Buca</div>
                <textarea style={{ resize: 'none', backgroundColor: 'white', borderRadius: 10, height: 300, width: '100%', padding: 20, border: '1px solid lightgrey' }} placeholder={"Inserici qui il testo"} value={this.state.testo} onChange={(e) => { this.setState({ testo: e.target.value, selection: e.target.selectionStart })}} />                

                <img src={ require('../../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.delete();
                    }
                }} />
                <img src={ require('../../images/save.png') } style={{ position: 'absolute', right: 50, bottom: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => {
                    // Update on server
                    this.save();
                }} />
            </section>
        );
    }
}