import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Button from '../components/Button';
import DragImage from './DragImage';
import DropImage from './DropImage';
import arrayShuffle from 'array-shuffle';

export default class TestoImmagine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            risposte: arrayShuffle(props.risposte), 
            testi: props.testi,
            associazioni: [],  
        };
    }

    componentDidMount() {
        
        this.reset();
    }

    getRisposta() {

        if (this.state.risposte.length > 0) {
            return null;
        }

        var risposta = [];
        this.state.associazioni.forEach((a) => {
            risposta.push(a.id);
        });

        return risposta;
    }

    setAssociazione(ind, id) {
        var assoc = this.state.associazioni;
        var risp = this.state.risposte;

        var value = this.props.risposte.find((r) => r.id === id);
        if (assoc[ind]) {
            risp.push(assoc[ind]);
        }

        assoc[ind] = value;

        risp.forEach((r, index) => {
            if (r.id === id) {
                risp.splice(index, 1);
            }
        });

        this.setState({ risposte: risp, associazioni: assoc });
        
    }

    reset() {

        var assoc = [];
        var risp = [];

        this.state.testi.forEach(() => {
            assoc.push(undefined);
        });

        this.props.risposte.forEach((r) => {
            risp.push(r);
        });

        this.setState({ associazioni: assoc, risposte: risp });
    }

    render() {

        return(
            <section className='question-container'>
                <DndProvider backend={HTML5Backend}>
                    <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold', marginBottom: 30 }}>Per ogni testo trascina il suo testo associato nella casella libera sulla destra.</p>
                    <div className='answer-container' style={{ flexDirection: 'column' }}>
                        <section className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                            { this.state.testi.map((t, ind) => {
                                
                                return(
                                    <div key={t.id} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <section className='text-container' style={{ flex: 1 }}>
                                            <p>{t.testo}</p>
                                        </section>
                                        <section style={{ flex: 0.5, marginLeft: -1 }}>
                                            <DropImage id={ind} immagine={this.state.associazioni[ind] ? this.state.associazioni[ind] : ''} setAssociazione={(ind, id) => this.setAssociazione(ind, id)} />
                                        </section>
                                    </div> 
                                );
                            })}
                        </section>
                    </div>

                    <div className='texttext-container' style={{ marginTop: 30 }}>
                        { this.state.risposte.map((r) => {

                            return(
                                <DragImage key={r.id} id={r.id} immagine={r.risposta} />
                            );
                        })}
                    </div>
                </DndProvider>
                
                <section style={{ marginTop: 20, marginBottom: -15, width: 100 }}>
                    <Button button={"RESET"} onClick={() => {
                        this.reset();
                    }} />
                </section>
            </section>
        );
    }
}