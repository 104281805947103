import React from 'react';
import Cookies from 'universal-cookie';
import { WaveLoading } from 'react-loadingg';
import getStatisticheCompleteInsegnante from '../services/getStatisticheCompleteInsegnante';
import { domande } from '../utils/tipologie';
import Button from '../components/Button';
import getInsegnantiBloccati from '../services/getInsegnantiBloccati';
import bloccaSbloccaInsegnante from '../services/disponibilitaInsegnante';
import getInfoInsegnante from '../services/getInfoInsegnante';
import setApprovazione from '../services/setApprovazioneInsegnante';

const cookies = new Cookies();

export default class InsegnanteWidget extends React.Component {
    constructor(props) {
        super(props);

        this.scuole = [
            {}, 
            { label: 'Scuola Primaria' }, 
            { label: 'Scuola Secondaria di I grado' }, 
            { label: 'Scuola Secondaria di II grado' }
        ];

        this.contratti = [
            {}, 
            { label: 'tempo determinato' }, 
            { label: 'tempo indeterminato' }
        ];

        this.state = {
            isLoading: false, 
            expanded: false, 
        };
    }

    async loadInfo() {
        this.setState({ isLoading: true });

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let stats = await getStatisticheCompleteInsegnante(username, token, this.props.insegnante.username);
        var info = undefined;

        if (this.props.insegnante.utente_scuola === '0') {
            info = await getInfoInsegnante(username, token, this.props.insegnante.username);
        }   
        
        var bloccati = [];
        let b = await getInsegnantiBloccati();
        b.forEach((ins) => {
            bloccati.push(ins.insegnante);
        });
        
        var t = [];
        var types = [];

        stats.forEach((s) => { 
            if (t.indexOf(parseInt(s.test)) === -1) {
                t.push(parseInt(s.test));
            } 
        });
        
        t.forEach((test, ind) => {
            stats.forEach((s) => {
                if (test === parseInt(s.test)) {
                    t[ind] = { id: test, descrizione: s.descrizione, count: 0 };
                }
            });
        });


        t.forEach((test) => {
            stats.forEach((s) => {
                if (test.id === parseInt(s.test)) {
                    test.count = test.count + 1;
                }
            })
        });

        domande.forEach((d) => {
            types.push({ id: d.value, tipo: d.label, count: 0 });
        });

        types.forEach((type, ind) => {
            stats.forEach((s) => {
                if (parseInt(s.tipo) === type.id) {
                    types[ind].count += 1;
                }
            });
        });
         
        this.setState({ tests: t, types, isLoading: false, bloccati, info });

    }

    async bloccaInsegnante() {

        if (this.state.bloccati.indexOf(this.props.insegnante.username) === -1 && window.confirm('Vuoi davvero bloccare questo insegnante? La sua utenza sarà completamente bloccata e non potrà più creare nessuna nuova domanda.')) {

            let username = cookies.get('ad_username');
            let token = cookies.get('ad_token');
            let res = await bloccaSbloccaInsegnante(username, token, this.props.insegnante.username, 1);
            if (res.result === 'ok') {
                let bl = this.state.bloccati;
                bl.push(this.props.insegnante.insegnante);
                this.setState({ bloccati: bl });
            }
        } else if (this.state.bloccati.indexOf(this.props.insegnante.username) !== -1 && window.confirm('Vuoi davvero sbloccare questo insegnante?')) {

            let username = cookies.get('ad_username');
            let token = cookies.get('ad_token');
            let res = await bloccaSbloccaInsegnante(username, token, this.props.insegnante.username, 0);
            if (res.result === 'ok') {
                let bl = this.state.bloccati;
                var index = bl.indexOf(this.props.insegnante.username);
                if (index !== -1) {
                    bl.splice(index, 1);
                    this.setState({ bloccati: bl });
                }
            }
        }
    }

    async setApprovazione(val) {

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await setApprovazione(username, token, this.props.insegnante.username, val, this.state.info.mail, this.state.info.cognome + ' ' + this.state.info.nome);
        console.log(res);
        if (!res || res.result === 'KO') {
            alert('Impossibile modificare l\'insegnante al momento. Per favore, riprova.');
            return;
        }

        alert('Insegnante aggiornato con successo. La pagina verrà ricaricata');
        window.location.reload();

    }

    render() {

        return(
            <div className='stats-container' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <section className='horizontal' style={{ marginTop: 5, marginBottom: -5 }}>
                    <section>
                        <h5>{this.props.insegnante.username}</h5>
                        { this.props.insegnante.utente_scuola === '1' && (
                            <div style={{ backgroundColor: '#E0E0E080', borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                                <span style={{ color: 'grey', fontSize: 10, fontWeight: 'lighter' }}>Utente SICPIA</span>
                            </div>
                        )}
                        { this.props.insegnante.approvato === '0' && (
                            <div style={{ backgroundColor: '#ECA7A7', borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                                <span style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>Da approvare</span>
                            </div>
                        )}
                    </section>
                    <p style={{ color: '#007bff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { if (!this.state.expanded) { this.loadInfo() } this.setState({ expanded: !this.state.expanded }); }}>{ this.state.expanded ? 'Chiudi' : 'Maggiori informazioni' }</p>
                </section>
                { this.state.expanded && (
                    <section className='info-insegnante'>
                        { this.state.isLoading && (
                            <WaveLoading />
                        )}
                        { !this.state.isLoading && (
                            <>
                                { this.state.info && (
                                    <section style={{ marginLeft: -20, marginTop: 10, marginBottom: 20 }}>
                                        <p style={{ fontWeight: 'bold', color: 'grey' }}>{this.state.info.cognome} {this.state.info.nome}</p>
                                        <p style={{ color: 'grey', marginTop: -15, fontSize: 11 }}>{this.state.info.data_nascita} - {this.state.info.luogo_nascita} ({this.state.info.stato_nascita})</p>
                                        <p style={{ color: 'grey', marginTop: -15, fontSize: 11 }}>Insegnante (classe di concorso {this.state.info.classe_concorso}) presso l'istituto di {this.scuole[parseInt(this.state.info.tipo_scuola)].label}: {this.state.info.scuola_appartenenza} con un contratto a {this.contratti[parseInt(this.state.info.tipo_contratto)].label}.</p>
                                        <p style={{ color: 'grey', marginTop: -10, fontSize: 11 }}>Residente a {this.state.info.citta} ({this.state.info.provincia}) all'indirizzo:{this.state.info.indirizzo}</p>
                                        <p style={{ color: 'grey', fontWeight: 'bold', marginTop: -10, fontSize: 11 }}>Email: <span style={{ fontWeight: 'normal' }}><a href={"mailto:" + this.state.info.mail}>{this.state.info.mail}</a></span> - Telefono: <span style={{ fontWeight: 'normal' }}>{this.state.info.telefono}</span></p>
                                    </section>
                                )}
                                { this.state.tests.map((test) => {

                                    return(
                                        <div key={test.id} className='answer answer-selectable' style={{ width: '100%', padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: -20, boxShadow: '0px 0px 0px lightgrey' }}>
                                            <h6>{test.descrizione}</h6>
                                            <p style={{ marginTop: -5, fontSize: 12 }}>{test.count} domande create da questo insegnante</p>
                                        </div>
                                    );
                                })}
                                { this.props.insegnante.approvato === '1' && (
                                    <section style={{ width: '180px', marginLeft: -20, marginTop: 20 }}>
                                        <Button button={ this.state.bloccati.indexOf(this.props.insegnante.username) === -1 ? 'Blocca insegnante' : 'Sblocca insegnante' } onClick={() => this.bloccaInsegnante()} />
                                    </section>
                                )}
                                { this.props.insegnante.approvato === '0' && (
                                    <section style={{ width: '300px', marginLeft: -20, marginTop: 20, marginBottom: -10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <p style={{ cursor: 'pointer', color: 'green', fontWeight: 'bold' }} onClick={() => this.setApprovazione(1)}>Approva</p>
                                        <p style={{ cursor: 'pointer', color: 'red', fontWeight: 'bold', marginLeft: 15 }} onClick={() => this.setApprovazione(0)}>Non approvare</p>
                                    </section>
                                )}
                            </>
                        )}
                    </section>
                )}
            </div>
        );
    }
}