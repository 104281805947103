import React from 'react';
import AudioPicker from '../../components/AudioPicker';
import TextInput from '../../components/TextInput';
import deleteDomanda from '../../services/deleteDomanda';
import updateDomanda from '../../services/updateDomanda';
import ReactAudioPlayer from 'react-audio-player';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class ModificaAudioMultipla extends React.Component {
    constructor(props) {
        super(props);

        let domanda = props.domanda;

        this.state = {
            id: domanda.id, 
            test: domanda.test, 
            audio: domanda.audio, 
            domanda: domanda.domanda, 
            risposte: JSON.parse(domanda.risposte), 
            risposta_corretta: parseInt(domanda.risposta_corretta), 
            trash: false, 
            deleted: false, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' };

        var allRisposte = true;
        this.state.risposte.forEach((r) => {
            if (!r.risposta) allRisposte = false;
        });

        if (this.state.domanda && this.state.risposta_corretta && allRisposte && this.state.audio) {
            
            this.setState({ error: false });

            var res = {
                tipo: 5, 
                audio: this.state.audio,
                domanda: this.state.domanda,
                risposta: this.state.risposta_corretta, 
                risposte: this.state.risposte
            }
            return res;
        }

        this.setState({ error: true });
    }

    async delete() {
        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await deleteDomanda(username, token, this.state.id);

        if (res.result === 'ok') {
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    async save() {
        let d = this.getQuestion();
        if (!d) return;

        let obj = {
            tipo: 5, 
            domanda: d.domanda, 
            risposte: JSON.stringify(d.risposte),
            audio: d.audio,
            allegato: null, 
        };

        let obj2 = {
            risposta_corretta: d.risposta,  
        };

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await updateDomanda(username, token, this.state.id, this.state.test, obj, obj2);

        if (res.result === 'ok') {
            alert('Salvataggio effettuato.');
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    setRisposta(id, text) {
        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.risposta = text;
            }
        });

        this.setState({ risposte });
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <section className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, marginLeft: '10%' }}>
                    <TextInput placeholder={'Testo della domanda'} value={this.state.domanda} fontSize={20} onChange={(text) => this.setState({ domanda: text })} />
                    <AudioPicker width={'50%'} height={150} value={this.state.audio} onChange={(audio) => this.setState({ audio })} />
                </section>
                <ReactAudioPlayer style={{ marginTop: '10px', width: '50%', marginLeft: '25%' }} src={this.state.audio} controls />
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold', marginLeft: '10%' }}>Risposte (selezionare poi quella corretta):</p>
                <div className='answer-container' style={{ flexDirection: 'column' }}>
                    { this.state.risposte.map((risposta) => {

                        return(
                            <section key={risposta.id} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <img src={ require('../../images/tick.png') } style={{ width: 20, height: 20, cursor: 'pointer', opacity: this.state.risposta_corretta === risposta.id ? 1.0 : 0.3 }} onClick={() => this.setState({ risposta_corretta: risposta.id })} />
                                <TextInput style={{ marginLeft: 15 }} placeholder={'Risposta'} value={risposta.risposta} fontSize={14} onChange={(text) => this.setRisposta(risposta.id, text)} />
                            </section>
                        );
                    })}
                </div>
                <img src={ require('../../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.delete();
                    }
                }} />
                <img src={ require('../../images/save.png') } style={{ position: 'absolute', right: 50, bottom: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => {
                    // Update on server
                    this.save();
                }} />
            </section>
        );
    }
}