import React from "react";
import Button from "../components/Button";

export default class ConsegnaTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    render() {

        return(
            <div className="backview" style={{ backgroundColor: '#3E3E3E4D' }}>
                <div className="over-view">
                    <img src={ require('../images/validating.png') } style={{ width: '30%', height: 'auto' }} alt="Validazione" />
                    <section style={{ width: '60%', marginLeft: '10%' }}>
                        <h4>Il test è terminato</h4>
                        <p>Per favore, attendi mentre il risultato viene calcolato. Non chiudere o aggiornare la pagina.</p>
                        { this.props.result && (
                            <div style={{ marginTop: 20, width: 200 }}>
                                <Button button={"Vedi risultato"} onClick={() => window.location.href = "/#/studente/risultato"} />
                            </div>
                        )}
                    </section>  
                </div>
            </div>
        );
    }
}