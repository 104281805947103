import React from "react";
import { withRouter } from "react-router-dom";
import '../../components/Layout/ReactToastify.css';

import '../landingPage/style.css';

import translateString from "../../utils/Locales";
const qs = require('querystring');
let t = translateString;

function Login(props) {
  
  return (<></>);
}

export default withRouter(Login);
