import React from 'react';
import Cookies from 'universal-cookie';
import ImagePicker from '../../components/ImagePicker';
import deleteDomanda from '../../services/deleteDomanda';
import updateDomanda from '../../services/updateDomanda';

const cookies = new Cookies();

export default class ModificaTestoImmagine extends React.Component {
    constructor(props) {
        super(props);

        let domanda = props.domanda;
        let risposte = JSON.parse(domanda.risposte);
        let domande = JSON.parse(domanda.domanda);

        let risp = []
        risposte.forEach((r, ind) => {
            risp.push({ id: ind + 1, testo: domande[ind].testo, risposta: r.risposta })
        });

        this.state = {
            id: domanda.id, 
            test: domanda.test, 
            risposte: risp, 
            trash: false, 
            deleted: false, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' };

        var allRisposte = true;
        var d = [];
        var risp = [];
        this.state.risposte.forEach((r) => {
            if (!r.risposta || !r.testo) allRisposte = false;

            d.push({ id: r.id, testo: r.testo });
            risp.push({ id: r.id, risposta: r.risposta });
        });

        if (allRisposte) {
            
            this.setState({ error: false });

            var res = {
                tipo: 3, 
                domande: d, 
                risposte: risp, 
            }
            return res;
        }

        this.setState({ error: true });
    }

    async delete() {
        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await deleteDomanda(username, token, this.state.id);

        if (res.result === 'ok') {
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    async save() {
        let d = this.getQuestion();
        if (!d) return;

        let obj = {
            tipo: 3, 
            domanda: JSON.stringify(d.domande), 
            risposte: JSON.stringify(d.risposte),
            audio: '',
            allegato: null, 
        };

        let obj2 = {
            risposta_corretta: '',  
        };

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await updateDomanda(username, token, this.state.id, this.state.test, obj, obj2);

        if (res.result === 'ok') {
            alert('Salvataggio effettuato.');
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    setPrincipale(id, text) {
        
        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.testo = text;
            }
        });

        this.setState({ risposte });
    }

    setAssociato(id, text) {

        var risposte = this.state.risposte;
        console.log(risposte);
        risposte.forEach((r) => {
            if (r.id === id) {
                r.risposta = text;
            }
        });

        this.setState({ risposte });
    }
    
    addRisposta() {
        let risposte = this.state.risposte;
        risposte.push({ id: this.state.risposte.length + 1, testo: '', risposta: '' });
        this.setState({ risposte });
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold', marginBottom: 30 }}>Inserisci fino a 5 testi con la relativa immagine corrispondente, le risposte saranno poi automaticamente mescolate in fase di presentazione allo studente.</p>
                <div className='answer-container' style={{ flexDirection: 'column' }}>
                    { this.state.risposte.map((r) => {

                        return(
                            <section key={r.id} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>
                                <textarea style={{ resize: 'none', flex: 1, minWidth: 100, height: 150, width: '100%', border: '1px solid #F3F3F3', padding: 20 }} placeholder={'Testo principale'} value={r.testo} onChange={(e) => this.setPrincipale(r.id, e.target.value)} />
                                <ImagePicker width={'50%'} height={150} value={r.risposta} onChange={(img) => this.setAssociato(r.id, img)} />
                            </section>
                        );
                    })}
                    { this.state.risposte.length < 5 && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer', marginTop: 20 }} onClick={() => this.addRisposta()}>
                            <img src={ require('../../images/add.png') } style={{ width: 20, height: 20 }} />
                            <span style={{ marginLeft: 10 }}>Aggiungi nuovo abbinamento</span>
                        </div>
                    )}
                    { this.state.risposte.length >= 5  && (
                        <span style={{ marginLeft: 10, marginTop: 20, color: 'grey' }}>Hai raggiunto il limite di 5 testi</span>
                    )}
                </div>
                <img src={ require('../../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.delete();
                    }
                }} />
                <img src={ require('../../images/save.png') } style={{ position: 'absolute', right: 50, bottom: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => {
                    // Update on server
                    this.save();
                }} />
            </section>
        );
    }
}