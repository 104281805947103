import React from 'react'
import { useDrag } from 'react-dnd'

function DragText({ id, testo }) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "text", 
        item: { id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        }),
    }))

  return (
    <section ref={drag} className='answer-text'>
        <p style={{ fontSize: 14, opacity: isDragging ? 0.5 : 1.0 }}>{testo}</p>
    </section>
  )
}

export default DragText