import React from "react";
import Cookies from "universal-cookie";
import filtraTentativi from "../services/filtraTentativi";
import getNazionalita from "../services/getNazionalita";
import SelectInput from "./SelectInput";

const cookies = new Cookies();

export default class FilterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.stati = [
            { value: null, label: 'Esito...' }, 
            { value: 'tentativi.esito < 12', label: 'Non superato' }, 
            { value: '(tentativi.esito = 12 OR tentativi.esito > 12)', label: 'Superato'}
        ];

        this.tests = [
            { value: null, label: 'Tipologia...' }
        ];
        props.tests.forEach((t) => {
            this.tests.push({ value: 'tentativi.test = ' + t.id, label: t.descrizione });
        });

        this.generi = [
            { value: null, label: 'Genere...' }, 
            { value: 'M', label: 'Maschio' }, 
            { value: 'F', label: 'Femmina'}
        ];

        this.state = {
            esito: this.stati[0], 
            test: this.tests[0], 
            arrNazionalita: [{ value: null, label: 'Nazionalità...' }],
            genere: this.generi[0],
            nazionalita: { value: null, label: 'Nazionalità...' },
            isLoading: true, 
        }
    }

    async componentDidMount() {

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token')
        let naz = await getNazionalita(username, token);
        let res = await filtraTentativi(username, token, null, null, null, null);

        if (this.props.onContent) {
            this.props.onContent(res);
        }

        var nazi = [{ value: null, label: 'Nazionalità...' }];

        naz.forEach((n) => {
            nazi.push({ value: n.stato_nascita, label: n.stato_nascita });
        });
        this.setState({ arrNazionalita: nazi, isLoading: false });

    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ padding: 15, borderRadius: 10, backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                <SelectInput
                    value={this.state.esito}
                    hidable={false}
                    isMulti={false}
                    filter={true}
                    options={this.stati} 
                    onChange={async (option) => {
                        this.setState({ esito: option });

                        let username = cookies.get('ad_username');
                        let token = cookies.get('ad_token')
                        let res = await filtraTentativi(username, token, option.value, this.state.test.value, this.state.genere.value, this.state.nazionalita.value);

                        if (this.props.onContent) {
                            this.props.onContent(res);
                        }
                    }}
                />
                <section style={{ height: 10, width: 20 }} />
                <SelectInput
                    value={this.state.test}
                    hidable={false}
                    isMulti={false}
                    filter={true}
                    options={this.tests} 
                    onChange={ async (option) => {
                        this.setState({ test: option });

                        let username = cookies.get('ad_username');
                        let token = cookies.get('ad_token')
                        let res = await filtraTentativi(username, token, this.state.esito.value, option.value, this.state.genere.value, this.state.nazionalita.value);

                        if (this.props.onContent) {
                            this.props.onContent(res);
                        }
                    }} 
                />
                <section style={{ height: 10, width: 20 }} />
                <SelectInput
                    value={this.state.genere}
                    hidable={false}
                    isMulti={false}
                    filter={true}
                    options={this.generi} 
                    onChange={ async (option) => {
                        this.setState({ genere: option });

                        let username = cookies.get('ad_username');
                        let token = cookies.get('ad_token')
                        let res = await filtraTentativi(username, token, this.state.esito.value, this.state.test.value, option.value, this.state.nazionalita.value);

                        if (this.props.onContent) {
                            this.props.onContent(res);
                        }
                    }} 
                />
                <section style={{ height: 10, width: 20 }} />
                <SelectInput
                    value={this.state.nazionalita}
                    hidable={false}
                    isMulti={false}
                    filter={true}
                    options={this.state.arrNazionalita} 
                    onChange={ async (option) => {
                        this.setState({ nazionalita: option });

                        let username = cookies.get('ad_username');
                        let token = cookies.get('ad_token')
                        let res = await filtraTentativi(username, token, this.state.esito.value, this.state.test.value, this.state.genere.value, option.value);

                        if (this.props.onContent) {
                            this.props.onContent(res);
                        }
                    }} 
                />
            </div>
        );
    }
}