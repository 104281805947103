import React from 'react';
import TextInput from '../../components/TextInput';
import EquationEditor from 'equation-editor-react';
import Cookies from 'universal-cookie';
import deleteDomanda from '../../services/deleteDomanda';
import updateDomanda from '../../services/updateDomanda';

var that = undefined;
const cookies = new Cookies();

export default class ModificaSceltaMultipla extends React.Component {
    constructor(props) {
        super(props);

        that = this;
        let domanda = props.domanda;

        this.state = {
            test: domanda.test,
            id: domanda.id, 
            domanda: domanda.domanda, 
            risposte: JSON.parse(domanda.risposte), 
            risposta_corretta: parseInt(domanda.risposta_corretta), 
            attachment: domanda.allegato ? (domanda.allegato.replace(/img:\/\/\//g, '').replace(/math:\/\/\//g, '')) : undefined, 
            adding: domanda.allegato ? (domanda.allegato.split(':///')[0]) : undefined, 
            trash: false, 
            deleted: false, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' };

        var allRisposte = true;
        this.state.risposte.forEach((r) => {
            if (!r.risposta) allRisposte = false;
        });

        if (this.state.domanda && this.state.risposta_corretta && allRisposte) {
            
            this.setState({ error: false });

            var res = {
                tipo: 1, 
                domanda: this.state.domanda,
                risposta: this.state.risposta_corretta, 
                risposte: this.state.risposte, 
                allegato: this.state.attachment ? this.state.adding + ':///' + this.state.attachment : null,
            }
            return res;
        }

        this.setState({ error: true });
    }

    async delete() {
        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await deleteDomanda(username, token, this.state.id);

        if (res.result === 'ok') {
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    setRisposta(id, text) {
        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.risposta = text;
            }
        });

        this.setState({ risposte });
    }

    handleFileSelected(e) {
        const files = Array.from(e.target.files)
        
        if (files[0].size <= 1048576) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                that.setState({ attachment: reader.result });
            };
        } else {
            alert("Scegliere un'immagine con una dimensione massima di 1MB");
        }
    }

    async save() {
        let d = this.getQuestion();
        if (!d) return;

        console.log(d);
        let obj = {
            tipo: 1, 
            domanda: d.domanda, 
            risposte: JSON.stringify(d.risposte),
            audio: '',
            allegato: d.allegato,
        };

        let obj2 = {
            risposta_corretta: d.risposta 
        };

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let res = await updateDomanda(username, token, this.state.id, this.state.test, obj, obj2);

        if (res.result === 'ok') {
            alert('Salvataggio effettuato.');
            this.props.reload();
            return;
        } 

        alert(res.description);
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <TextInput placeholder={'Testo della domanda'} value={this.state.domanda} fontSize={20} onChange={(text) => this.setState({ domanda: text })} />
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                    { !this.state.adding && (
                        <>
                            <p style={{ color: 'blue', cursor: 'pointer', fontSize: 14, fontWeight: 'bold', marginRight: 20 }} onClick={() => this.setState({ adding: 'img', attachment: '' })}>Aggiungi immagine</p>
                            <p style={{ color: 'blue', cursor: 'pointer', fontSize: 14, fontWeight: 'bold' }} onClick={() => this.setState({ adding: 'math', attachment: 'y=x' })}>Aggiungi formula</p>
                        </>
                    )}

                    { this.state.adding === 'math' && (
                        <EquationEditor
                            value={this.state.attachment}
                            onChange={(equazione) => this.setState({ attachment: equazione })}
                            autoCommands="pi theta sqrt sum prod alpha beta gamma delta rho"
                            autoOperatorNames='sin cos tan arccos arcsen arctan'
                        />
                    )}
                    { this.state.adding === 'img' && (
                        <div style={{ flexDirection: 'column' }}>
                            <input type={'file'} accept=".png, .jpg, .jpeg" onChange={this.handleFileSelected} />
                            { this.state.attachment && (
                                <img src={this.state.attachment} style={{ width: '50%', height: 'auto' }} />
                            )}
                        </div>
                    )}
                </section>
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold' }}>Risposte (selezionare poi quella corretta):</p>
                <div className='answer-container' style={{ flexDirection: 'column' }}>
                    { this.state.risposte.map((risposta) => {

                        return(
                            <section key={risposta.id} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <img src={ require('../../images/tick.png') } style={{ width: 20, height: 20, cursor: 'pointer', opacity: this.state.risposta_corretta === risposta.id ? 1.0 : 0.3 }} onClick={() => this.setState({ risposta_corretta: risposta.id })} />
                                <TextInput style={{ marginLeft: 15 }} placeholder={'Risposta'} value={risposta.risposta} fontSize={14} onChange={(text) => this.setRisposta(risposta.id, text)} />
                            </section>
                        );
                    })}
                </div>
                <img src={ require('../../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.delete();
                    }
                }} />
                <img src={ require('../../images/save.png') } style={{ position: 'absolute', right: 50, bottom: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => {
                    // Update on server
                    this.save();
                }} />
            </section>
        );
    }
}