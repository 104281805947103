

export const domande = [
    { value: 0, label: 'Vero/Falso' },
    { value: 1, label: 'Scelta multipla' }, 
    { value: 2, label: 'Abbinamento testo/testo' }, 
    { value: 3, label: 'Abbinamento immagine/testo' },
    { value: 4, label: 'Ascolto di un brano (VERO/FALSO)' }, 
    { value: 5, label: 'Ascolto di un brano (risposta multipla)' }, 
    { value: 6, label: 'Cloze (Completamento testo)' }, 
    { value: 7, label: 'Ricostruzione concettuale' }, 
    { value: 8, label: 'Completamento affermazione' }
];