import Constants from '../utils/consts';

export default async function checkTokenStudenti(username, token) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'checkTokenStudenti.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({ }), 
            headers: {
                'Authorization': 'Bearer ' + username + '.' + token,
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
 