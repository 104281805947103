import React from 'react';
import { useDrop } from 'react-dnd';

function DropImage(props) {

    const [{ isOver }, drop ] = useDrop(() => ({
        accept: "image", 
        drop: (item) => { props.setAssociazione(props.id, item.id); },
    }));

  return (
    <div ref={drop} className='text-container'>
        <img src={props.immagine.risposta} style={{ height: '100%', width: 'auto' }}/>
    </div>
  )
}

export default DropImage;