import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { withStyles } from '@material-ui/styles';

import '../../pages/landingPage/style.css';

import { getServiceName } from "../../utils/consts";
import translateString from "../../utils/Locales";
import { ToastContainer } from 'react-toastify';
import './ReactToastify.css';
import Cookies from "universal-cookie";
import Navbar from "../Navbar";
import checkTokenStudenti from "../../services/checkTokenStudenti";
import DashboardStudente from "../../pages/DashboardStudente";
import InfoTest from "../../pages/InfoTest";
import Test from "../../pages/Test";
import RisultatoTest from "../../pages/RisultatoTest";
import PaginaRisultato from "../../pages/PaginaRisultato";

let t = translateString;

const makeStyles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    minHeight: '100vh',
    overflowX: "visible",
    backgroundColor: '#EEEEEE', 
    paddingLeft: '50px', 
    paddingRight: '50px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px) `,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
});

const cookies = new Cookies();

class StudenteLayout extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      menuItems: []
    }
  }

  async componentDidMount(){
    window.document.title = getServiceName() + ' - ' + t('gestionale')

    let username = cookies.get('username');
    let token = cookies.get('token');

    if (!username || !token) {
      cookies.remove('username');
      cookies.remove('token');
      window.location.href = '/#/main/home';
      return;
    }

    let check = await checkTokenStudenti(username, token);
    if (!check || check.result === 'KO') {
      cookies.remove('username');
      cookies.remove('token');
      window.location.href = '/#/main/home';
      return;
    }
  }

  render (){

    // global
    const { classes } = this.props;

    return (
      
      <div className={classes.root}>
          <>
            <Navbar tipo="studente" />
            <ToastContainer style={{ marginTop: 60 }} />

            <div style={{ marginTop: 150, position: 'relative', width: '100vw', minHeight: '100%' }}>
              <Switch>
                <Route path='/studente/dashboard' component={DashboardStudente} />
                <Route path='/studente/inizia' component={InfoTest} /> 
                <Route path='/studente/test' component={Test} />
                <Route path='/studente/risultato' component={PaginaRisultato} />
                <Route path='/studente/pdf' component={RisultatoTest} />
              </Switch>
            </div>
          </>
      </div>
    );
  }
  
}

export default withStyles(makeStyles)(withRouter(StudenteLayout));
