import React from "react";
import { FilePicker } from 'react-file-picker'
import translateString from "../utils/Locales";

let t = translateString;
var that = undefined;

export default class AudioPicker extends React.Component {

    constructor (props){
        super(props);

        that = this;

        this.state = {
            base64: this.props.value, 
        }
    }

    render (){
        return ( 
        <div style={{ ...mainClass, ...this.props.style }}>
            <FilePicker
                extensions={['mp3', 'wav', 'm4a', 'flac', 'mp4', 'wma', 'aac']}
                maxSize={1024}
                onChange={file => this.getBase64(file, this.props.onChange)}
                onError={() => this.props.onError && this.props.onError()} >

                <div style={{ backgroundColor: '#F3F3F3', borderRadius: 10, padding: 30, display: 'flex', flex: 0.33, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: this.props.height, width: '100%', cursor: 'pointer' }}>
                    { this.state.base64 && ( 
                        <section>
                            <img src={ require('../images/mic.png') } style={{ width: 30, height: 30, marginTop: 10 }} alt="mic img"/> 
                            <p style={{ color: '#2196F3', textAlign: 'center', marginTop: 5 }}>Audio caricato</p>
                        </section>
                    )}
                    { !this.state.base64 && (
                        <section>
                            <img src={ require('../images/add.png') } style={{ width: 30, height: 30, marginTop: 10 }} alt="plus img"/> 
                            <p style={{ color: '#2196F3', textAlign: 'center', marginTop: 5 }}>Scegli audio<br/>(1 MB)</p>
                        </section>
                    )}
                </div>
            
            </FilePicker>
        </div>
        )
    }

    getBase64(file, cb) {

        console.log(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
            that.setState({ base64: reader.result });
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

}

const mainClass = {
    padding: '10px',
}
