import Constants from '../utils/consts';

export default async function updateDomanda(username, token, id, test, domanda, risposta) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'updateDomanda.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'id': id, 
                'username_insegnante': username, 
                'test': test, 
                'domanda': domanda.domanda, 
                'tipo': domanda.tipo, 
                'risposte': domanda.risposte, 
                'audio': domanda.audio,
                'allegato': domanda.allegato,
                'risposta_corretta': risposta.risposta_corretta
            }), 
            headers: {
                'Authorization': 'Bearer ' + username + '.' + token,
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
