import React from 'react';
import TextInput from '../components/TextInput';

export default class NuovoCloze extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testo: '', 
            trash: false, 
            deleted: false,
            selection: undefined, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' }

        if (this.state.testo && this.state.testo.includes('%-')) {

            var parole = [];
            var text = this.state.testo;

            var ind = text.indexOf('%-');
            var count = 1;
            while(ind !== -1) {
            
                let sub = text.substring(ind + 2, text.indexOf('-%'));
                parole.push({ id: count, risposta: sub });
                count++;
                text = text.substring(text.indexOf('-%') + 2, text.length);
                ind = text.indexOf('%-');
            }

            var testo = this.state.testo;
            var cleanInd = testo.indexOf('%-');
            var count = 0;
            while(cleanInd !== -1 && count < 1000) {
            
                let sub = testo.substring(cleanInd, testo.indexOf('-%') + 2);
                testo = testo.replace(sub, '%%!%%');
                cleanInd = testo.indexOf('%-');
                count++;
            }

            this.setState({ error: false });
            
            var res = {
                tipo: 6, 
                domanda: testo,
                risposte: parole,
            }
            return res;
        }

        this.setState({ error: true });
    }

    delete() {
        this.setState({ deleted: true });
    }

    // FOR TESTING ONLY // 
    /* componentDidUpdate() {

        var parole = [];
        var text = this.state.testo;

        var ind = text.indexOf('%-');
        while(ind !== -1) {
           
            let sub = text.substring(ind + 2, text.indexOf('-%'));
            parole.push(sub);
            text = text.substring(text.indexOf('-%') + 2, text.length);
            ind = text.indexOf('%-');
        }

        var testo = this.state.testo;
        var cleanInd = testo.indexOf('%-');
        var count = 0;
        while(cleanInd !== -1 && count < 1000) {
        
            let sub = testo.substring(cleanInd, testo.indexOf('-%') + 2);
            testo = testo.replace(sub, '%!%');
            cleanInd = testo.indexOf('%-');
            count++;
        }

    } */

    addBuco() {

        if (this.state.selection) {

            let cursorPosition = this.state.selection
            let textBeforeCursorPosition = this.state.testo.substring(0, cursorPosition)
            let textAfterCursorPosition = this.state.testo.substring(cursorPosition, this.state.testo.length)
            this.setState({ testo: textBeforeCursorPosition + '%-parola qui-%' + textAfterCursorPosition });
        }
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold' }}>Inserisci un testo e, dove vuoi inserire una parola vuota, clicca il pulsante qui sotto. Inserisci poi la parola corretta dove leggi "parola qui". (Ad es, se voglio che lo studente selezioni la parola "esempio": %-parola qui-% diventa %-esempio-%).</p>
                <div style={{ backgroundColor: 'white', boxShadow: '0px 2px 10px lightgrey', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: 'darkgrey', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 10, borderRadius: 10, width: 100, cursor: 'pointer' }} onClick={() => this.addBuco()}>Buca</div>
                <textarea style={{ resize: 'none', backgroundColor: 'white', borderRadius: 10, height: 300, width: '100%', padding: 20, border: '1px solid lightgrey' }} placeholder={"Inserici qui il testo"} value={this.state.testo} onChange={(e) => { this.setState({ testo: e.target.value, selection: e.target.selectionStart })}} />                

                <img src={ require('../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.setState({ deleted: true });
                    }
                }} />
            </section>
        );
    }
}