import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

export default class AudioVeroFalso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            audio: props.audio, 
            domanda: props.domanda, 
            risposta: 0,
        };
    }

    getRisposta() {
        return this.state.risposta;
    }

    render() {

        return(
            <section className='question-container'>
                <p style={{ color: '#818181', marginTop: 10, fontWeight: 'bold' }}>Clicca sulla risposta che completa l'affermazione qui sotto.</p>
                <section className='answer answer-selectable' style={{ marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, marginLeft: '10%' }}>
                    <ReactAudioPlayer style={{ marginTop: '10px' }} src={this.state.audio} controls />
                    <h5 style={{ marginTop: 30 }}>{this.state.domanda}</h5>
                </section>
                <div className='answer-container' style={{ marginTop: 50 }}>
                    <section className='answer' style={{ backgroundColor: this.state.risposta === 1 ? '#C3F3B3' : 'white' }} onClick={() => this.setState({ risposta: 1 })}>Vero</section>
                    <section className='answer' style={{ backgroundColor: this.state.risposta === 2 ? '#C3F3B3' : 'white' }} onClick={() => this.setState({ risposta: 2 })}>Falso</section>
                </div>
            </section>
        );
    }
}