import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { withStyles } from '@material-ui/styles';

import '../../pages/landingPage/style.css';

import { getServiceName } from "../../utils/consts";
import translateString from "../../utils/Locales";
import { ToastContainer } from 'react-toastify';
import './ReactToastify.css';
import Cookies from "universal-cookie";
import checkToken from "../../services/checkToken";
import Dashboard from "../../pages/Dashboard";
import Navbar from "../Navbar";
import NuovoTest from "../../pages/NuovoTest";
import checkAdminToken from "../../services/checkAdminToken";
import AdminLogin from "../../pages/AdminLogin";
import AdminDashboard from "../../pages/AdminDashboard";
import GestioneInsegnanti from "../../pages/GestioneInsegnanti";
import RisultatoAdmin from "../../pages/RisultatoAdmin";
import Statistiche from "../../pages/Statistiche";
import GestioneDomande from "../../pages/GestioneDomande";

let t = translateString;

const makeStyles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    minHeight: '100vh',
    overflowX: "visible",
    backgroundColor: '#EEEEEE', 
    paddingLeft: '50px', 
    paddingRight: '50px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px) `,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
});

const cookies = new Cookies();

class AdminLayout extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      menuItems: []
    }
  }

  async componentDidMount(){

    let username = cookies.get('ad_username');
    let token = cookies.get('ad_token');

    if (!username || !token) {
      cookies.remove('ad_username');
      cookies.remove('ad_token');
      window.location.href = '/#/admin/login';
      return;
    }

    let check = await checkAdminToken(username, token);
    if (!check || check.result === 'KO') {
      cookies.remove('ad_username');
      cookies.remove('ad_token');
      window.location.href = '/#/admin/login';
      return;
    }
  }

  render (){

    // global
    const { classes } = this.props;

    return (
      
      <div className={classes.root}>
          <>
            <Navbar tipo="amministratore" />
            <ToastContainer style={{ marginTop: 60 }} />

            <div style={{ marginTop: 150, position: 'relative', width: '100vw', minHeight: '100%' }}>
              <Switch>
                <Route path='/admin/login' component={AdminLogin} /> 
                <Route path='/admin/dashboard' component={AdminDashboard} /> 
                <Route path='/admin/insegnanti' component={GestioneInsegnanti} />
                <Route path='/admin/risultato' component={RisultatoAdmin} /> 
                <Route path='/admin/statistiche' component={Statistiche} />
                <Route path='/admin/domande' component={GestioneDomande} /> 
              </Switch>
            </div>
          </>
      </div>
    );
  }
  
}

export default withStyles(makeStyles)(withRouter(AdminLayout));
