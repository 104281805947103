import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import SelectInput from '../components/SelectInput';
import addDomanda from '../services/addDomanda';
import getTests from '../services/getTests';
import NuovaRicostruzione from '../widgets/NuovaRicostruzione';
import NuovaSceltaMultipla from '../widgets/NuovaSceltaMultipla';
import NuovoAudioMultipla from '../widgets/NuovoAudioMultipla';
import NuovoAudioVF from '../widgets/NuovoAudioVF';
import NuovoCloze from '../widgets/NuovoCloze';
import NuovoCompletamento from '../widgets/NuovoCompletamento';
import NuovoTestoImmagine from '../widgets/NuovoTestoImmagine';
import NuovoTestoTesto from '../widgets/NuovoTestoTesto';
import NuovoVF from '../widgets/NuovoVF';
import QuestionSelectionWidget from '../widgets/QuestionSelectionWidget';

const cookies = new Cookies();

export default class NuovoTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true, 
            titolo: '', 
            tipo: { value: '', label: 'Tipologia di test...'}, 
            event: undefined, 
            newQuestion: false,
            tipoDomande: [],
            domande: [],
            refs: [],
            uploading: false, 
            questionCount: 0,
        };
    }

    async componentDidMount() {

        let res = await getTests();
        var tipologie = [];

        res.forEach((t) => {
            tipologie.push({ value: t.id, label: t.descrizione });
        });

        this.setState({ isLoading: false, tipologie });
    }

    addNewQuestion(tipo) {
        
        this.setState({ newQuestion: false, event: undefined });

        var q = this.state.tipoDomande;
        q.push(tipo);
        var d = this.state.domande;
        d.push({});
        let res = React.createRef();
        var refs = this.state.refs;
        refs.push(res);

        this.setState({ tipoDomande: q, domande: d, refs });
    }

    async sendDomande() {

        var confirm = true;
        let domande = [];
        this.state.refs.forEach((r, ind) => {

            var domanda = r.current.getQuestion();
            if (!domanda) {
                confirm = false;
                return;
            }

            if (domanda.tipo === 'deleted') {
                return;
            }

            domanda.refInd = ind;
            domande.push(domanda);
        });

        if (!confirm) { 
            alert('Sono stati rilevati alcuni errori all\'interno delle domande create. Per favore, verifica tutti i dati e riprova.');
            return;
        }

        this.setState({ uploading: true, questionCount: 0 });

        let domandeFinali = [];
        let risposteFinali = [];

        domande.forEach((d) => {
            
            if (d.tipo === 0) {
                
                // Vero o Falso 
                let obj = {
                    tipo: 0, 
                    domanda: d.domanda, 
                    risposte: '',
                    audio: '',
                    allegato: d.allegato
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: d.risposta 
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 1) {

                // Risposta multipla
                let obj = {
                    tipo: 1, 
                    domanda: d.domanda, 
                    risposte: JSON.stringify(d.risposte),
                    audio: '',
                    allegato: d.allegato,
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: d.risposta 
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 2) {
                
                // Abbinamento testo - testo 
                let obj = {
                    tipo: 2, 
                    domanda: JSON.stringify(d.domande), 
                    risposte: JSON.stringify(d.risposte),
                    audio: '',
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: '',  
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 3) {

                // Abbinamento testo - immagine 
                let obj = {
                    tipo: 3, 
                    domanda: JSON.stringify(d.domande), 
                    risposte: JSON.stringify(d.risposte),
                    audio: '',
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: '',  
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 4) {

                // Audio - Vero/Falso
                let obj = {
                    tipo: 4, 
                    domanda: d.domanda, 
                    risposte: '',
                    audio: d.audio,
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: d.risposta,  
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 5) {

                // Audio - Risposta multipla
                let obj = {
                    tipo: 5, 
                    domanda: d.domanda, 
                    risposte: JSON.stringify(d.risposte),
                    audio: d.audio,
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: d.risposta,  
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 6) {

                // Cloze
                let obj = {
                    tipo: 6, 
                    domanda: d.domanda, 
                    risposte: JSON.stringify(d.risposte),
                    audio: '',
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: '',  
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 7) {
             
                // Ricostruzione concettuale
                let obj = {
                    tipo: 7, 
                    domanda: d.domanda, 
                    risposte: JSON.stringify(d.risposte),
                    audio: '',
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: '',  
                };
                risposteFinali.push(obj2);
            } else if (d.tipo === 8) {

                // Completamento affermazione
                let obj = {
                    tipo: 8, 
                    domanda: d.domanda, 
                    risposte: JSON.stringify(d.risposte),
                    audio: '',
                    allegato: null, 
                };
                domandeFinali.push(obj);

                let obj2 = {
                    risposta_corretta: d.risposta,  
                };
                risposteFinali.push(obj2);
            }

        });

        // Upload
        var indexes = [];
        var count = 0;
        domandeFinali.forEach((d, ind) => {
            
            let username = cookies.get('username');
            let token = cookies.get('token');

            addDomanda(username, token, this.state.tipo.value, d, risposteFinali[ind]).then((res) => {
                if (res.result === 'ok') {
                    indexes.push(ind);
                }
                
                count++;
                this.setState({ questionCount: count + 1 });
                if (count === domandeFinali.length) {
                    this.notifyResult(indexes, domande, domandeFinali.length);
                } 
            });
        });

    }

    notifyResult(indexes, domande, count) {

        this.setState({ uploading: false });
        
        indexes.forEach((i) => {
            let ind = domande[i].refInd;
            this.state.refs[ind].current.delete();
        });

        if (indexes.length === count) {
            alert('Caricamento completato con successo.');
            window.location.href = '/#/app/tests';
            return;
        }

        alert('Si sono verificati alcuni errori durante il caricamento. Le domande rimaste visibili NON sono state caricate. Per favore, riprova.');
        return;
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden', marginBottom: 100 }}>  
                <h3 className='title'>Creazione nuove domande per Self-Assessment</h3>
                <div className='dashboard-container' style={{ marginTop: 10 }}>
                    <SelectInput
                        value={this.state.tipo} 
                        name={''} 
                        options={this.state.tipologie} 
                        onChange={(option) => { this.setState({ tipo: option }); }}
                    />
                </div>
                { this.state.tipo.value !== '' && (
                    <div className='dashboard-container' style={{ marginTop: 10 }}>
                        <h5>Domande</h5>
                        <div style={{ marginTop: 20 }}>
                            { this.state.tipoDomande.map((tipo, ind) => {
                                
                                if (tipo === 0) {
                                    return (
                                        <NuovoVF key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 1) {
                                    return(
                                        <NuovaSceltaMultipla key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 2) {
                                    return (
                                        <NuovoTestoTesto key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 3) {
                                    return(
                                        <NuovoTestoImmagine key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 4) {
                                    return(
                                        <NuovoAudioVF key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 5) {
                                    return(
                                        <NuovoAudioMultipla key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 6) {
                                    return(
                                        <NuovoCloze key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 7) {
                                    return(
                                        <NuovaRicostruzione key={ind} ref={this.state.refs[ind]} />
                                    );
                                } else if (tipo === 8) {
                                    return(
                                        <NuovoCompletamento key={ind} ref={this.state.refs[ind]} />
                                    );
                                }

                                return <></>;
                            })}
                        </div>
                        <div className='add-question-btn' onClick={(e) => this.setState({ newQuestion: true, event: { x: e.pageX, y: e.screenY - 200 } })}>
                            <img src={ require('../images/plus_white.png') } style={{ width: 30, height: 30, marginTop: 10 }} />
                            <p style={{ fontWeight: 'bold', fontSize: 20, marginTop: 15, marginBottom: 5 }}>Nuova domanda</p>
                        </div>

                        { this.state.tipoDomande.length > 0 && (
                            <div style={{ marginTop: 20 }}>
                                <Button style={{ marginTop: 30 }} button={!this.state.uploading ? 'INVIA DOMANDE' : 'INVIO... ' + this.state.questionCount + '/' + this.state.tipoDomande.length} onClick={() => !this.state.uploading && this.sendDomande()} />
                            </div>
                        )}
                    </div>
                )}
                { this.state.newQuestion && (
                    <QuestionSelectionWidget event={this.state.event} onSelection={(question) => this.addNewQuestion(question)} onClose={() => this.setState({ newQuestion: false, event: undefined })} />
                )}
            </div>
        );
    }

}