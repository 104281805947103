import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import mainLogin from '../services/mainLogin';

const cookies = new Cookies();

export default class AdminLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '', 
            password: '', 
            error: false, 
            errorMessage: '', 
        };
    }

    async login() {

        if (!this.state.username.trim() || !this.state.password.trim()) {
            this.setState({ error: true, errorMessage: 'Verifica i dati inseriti' });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        let res = await mainLogin(this.state.username, this.state.password, 0);

        if (!res.user || !res.token) {
            this.setState({ error: true, errorMessage: 'Credenziali non corrette' });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        cookies.set('ad_username', this.state.username);
        cookies.set('ad_token', res.token);

        window.location.href = '/#/admin/dashboard';

    }

    render() {

        return(
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='login-container'>
                    <img src={ require('../images/icon.png') } style={{ width: 100, height: 100, objectFit: 'contain' }} />
                    <section style={{ marginTop: 50, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        { this.state.error && ( <p style={{ color: 'red', fontWeight: 'bold', marginTop: -20, marginBottom: 0 }}>{this.state.errorMessage}</p> )}
                        <TextInput width={'80%'} placeholder={'Username'} type={'text'} fontSize={14} onChange={(username) => this.setState({ username })} />
                        <br/>
                        <TextInput width={'80%'} placeholder={'Password'} type={'password'} fontSize={14} onChange={(password) => this.setState({ password })} />
                    </section>
                    <section style={{ marginTop: 50, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Button animated={false} button={'ACCEDI'} onClick={() => this.login()} />
                    </section>
                </div>
            </div>
        );
    }
}