import React from 'react';

const Latex = require('react-latex');

export default class VeroFalso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domanda: props.domanda, 
            risposta: undefined, 
            allegato: props.allegato, 
        };
    }

    getRisposta() {
        
        if (this.state.risposta)
            return this.state.risposta;
        else 
            return 0;
    }

    render() {

        return(
            <section className='question-container'>
                <h5>{this.state.domanda}</h5>
                { this.state.allegato && (
                    <section style={{ marginTop: 20 }}>
                        { this.state.allegato.includes('math:///') ? (
                            <Latex displayMode={true}>{'$$' + this.state.allegato.replace('math:///', '') + '$$'}</Latex>
                        ) : (
                            <img alt="Allegato" src={this.state.allegato.replace('img:///', '')} style={{ width: '40%', height: 'auto', marginLeft: '30%' }} />
                        )}
                    </section>
                )}
                <div className='answer-container' style={{ marginTop: 40 }}>
                    <section className='answer' style={{ backgroundColor: this.state.risposta === 1 ? '#C3F3B3' : 'white' }} onClick={() => this.setState({ risposta: 1 })}>Vero</section>
                    <section className='answer' style={{ backgroundColor: this.state.risposta === 2 ? '#C3F3B3' : 'white' }} onClick={() => this.setState({ risposta: 2 })}>Falso</section>
                </div>
            </section>
        );
    }
}