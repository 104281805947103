import Constants from '../utils/consts';
import moment from 'moment';

var uuid = require("uuid");

export default async function loginStudente(username, password) {
    return new Promise(function (resolve, reject) {

        let deviceUniqueId = uuid.v4();
        let requestTimestamp = moment().format('yyyy-MM-DDTHH:mm:ss');

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'loginStudente.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'username': username, 
                'password': password, 
                'udid': deviceUniqueId, 
                'timestamp': requestTimestamp
            }), 
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
