import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';
import jwt_decode from 'jwt-decode';

// components
import Layout from "./Layout";
import MainLayout from './MainLayout/MainLayout';

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Cookies from 'universal-cookie';

// context
import Constants from "../utils/consts";

// Functions
import { getServiceName } from "../utils/consts";
import AdminLayout from "./AdminLayout/AdminLayout";
import StudenteLayout from "./StudenteLayout/StudenteLayout";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true, // won't minify CSS classnames when true
  productionPrefix: 'c', // 'jss' by default
});

var isAuthenticated;
var isAdminAuth;

export default class App extends React.Component{
  
  constructor() {
    super();
    this.state = { isLoading: true };
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

handleCallbackResponse(response) {

  var userObject = jwt_decode(response.credential);
  console.log(userObject);
}

  async componentDidMount(){

    const cookies = new Cookies();
    var token = cookies.get('token', { path: '/' });
    var uname = cookies.get('username', { path: '/' });
    cookies.set('mainColor', 'F58320', { path: '/' });
    cookies.set('secondaryColor', 'FFC260', { path: '/' });
    const db = cookies.get('db', { path: '/' });

    this.client = undefined;

    document.title = getServiceName();

    this.setState({ isLoading: false });
  }

  render() {

    if (this.state.isLoading === true){
      return <div />;
    }

    const cookies = new Cookies();
    const userType = window.sessionStorage.getItem('user_type');

    var redirectUrl = '';

    if (window.location.href.split('/')[3].includes('main') || window.location.href.split('/')[3] === '') {
      redirectUrl = '/main/home';
    } else if (window.location.href.split('/')[3].includes('admin')) {
      redirectUrl = "admin/dashboard";
    } else {
      redirectUrl = "/app/tests";
    }

      return (
        <JssProvider generateClassName={ generateClassName }>
        <HashRouter>
          <Switch>
  
            <Route exact path="/" render={() => <Redirect to={redirectUrl} />} />
            <PrivateRoute path="/app" component={Layout} />
            <PrivateRoute path="/main" component={MainLayout} />
            <PrivateRoute path="/admin" component={AdminLayout} />
            <PrivateRoute path="/studente" component={StudenteLayout} />
            <Route component={Error} />
          </Switch>
        </HashRouter>
        </JssProvider>
      );
      
    }
  }

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
        
    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(component, props)
        }
      />
    );
  }

