import React from 'react';
import { domande } from '../utils/tipologie';

const Question = (props) => {

    return(
        <section className='question-selection' onClick={() => props.onClick(props.value)}>
            <p style={{ fontWeight: '400', color: '#818181', textAlign: 'left', marginTop: 15 }}>{props.label}</p>
        </section>
    );
}

export default class QuestionSelectionWidget extends React.Component {
    constructor(props) {
        super(props);

        console.log(this.props.event);

        this.state = {

        };
    }

    render() {

        return(
            <div className='backview' onClick={() => this.props.onClose()}>
                <div className='context-menu' style={{ position: 'absolute', left: this.props.event.x, top: this.props.event.y }} onClick={(e) => e.stopPropagation()}>
                    { domande.map((domanda) => {

                        return (
                            <Question key={Math.random()} label={domanda.label} value={domanda.value} onClick={this.props.onSelection} />
                        );
                    })}
                </div>
            </div>
        );
    }
}