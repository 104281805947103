import React from 'react';
import AudioPicker from '../components/AudioPicker';
import TextInput from '../components/TextInput';

export default class NuovoAudioVF extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            audio: '', 
            domanda: '', 
            risposta_corretta: undefined, 
            trash: false, 
            deleted: false,
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' }

        if (this.state.domanda && this.state.risposta_corretta && this.state.audio) {

            this.setState({ error: false });
            
            var res = {
                tipo: 4, 
                audio: this.state.audio, 
                domanda: this.state.domanda,
                risposta: this.state.risposta_corretta, 
            }
            return res;
        }

        this.setState({ error: true });
    }

    delete() {
        this.setState({ deleted: true });
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <section className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, marginLeft: '10%' }}>
                    <TextInput placeholder={'Testo della domanda'} value={this.state.domanda} fontSize={20} onChange={(text) => this.setState({ domanda: text })} />
                    <AudioPicker width={'50%'} height={150} value={this.state.audio} onChange={(audio) => this.setState({ audio })} />
                </section>
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold' }}>Risposta corretta:</p>
                <div className='answer-container'>
                    <section className='answer' style={{ backgroundColor: this.state.risposta_corretta === 1 ? '#C3F3B3' : 'white' }} onClick={() => this.setState({ risposta_corretta: 1 })}>Vero</section>
                    <section className='answer' style={{ backgroundColor: this.state.risposta_corretta === 2 ? '#C3F3B3' : 'white' }} onClick={() => this.setState({ risposta_corretta: 2 })}>Falso</section>
                </div>
                <img src={ require('../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.setState({ deleted: true });
                    }
                }} />
            </section>
        );
    }
}