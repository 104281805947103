import React from 'react';
import { useDrop } from 'react-dnd';

function DropText(props) {

    const [{ isOver }, drop ] = useDrop(() => ({
        accept: "text", 
        drop: (item) => { props.setAssociazione(props.id, item.id); },
    }));

  return (
    <div ref={drop} className='text-container'>
        <p>{props.testo.risposta}</p>
    </div>
  )
}

export default DropText;