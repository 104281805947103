import React from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    render() {

        return(
            <input className='custom-input' style={{ fontWeight: this.props.weight ? this.props.weight : 'normal', width: this.props.width ? this.props.width : '100%', fontSize: this.props.fontSize ? this.props.fontSize : 30, ...this.props.style}} type={this.props.type} value={this.props.value} placeholder={this.props.placeholder} onChange={(e) => this.props.onChange(e.target.value)} /> 
        );
    }

}