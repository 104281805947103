import React, { Component } from 'react'
import translateString from '../utils/Locales';
import '../pages/landingPage/style.css';
import { WaveLoading } from 'react-loadingg';
import loginInsegnante, { login } from '../services/login';
import Cookies from 'universal-cookie';
import setToken from '../services/setToken';


let t = translateString;

export default class LoginWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '', 
            password: '',
            error: false,
            errorMessage: '',
            isLoading: false,
        }; 
    }

    async handleLogin() {
        if (!this.state.username || !this.state.password) {
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.setState({ isLoading: true, error: false, errorMessage: '' });

        let res = await loginInsegnante(this.state.username, this.state.password);
        if (!res || (res.user === null && res.token === null)) {
            this.setState({ isLoading: false, error: true, errorMessage: t('err_credenziali') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        } else if (res.user === null && res.descrizione) {
            this.setState({ isLoading: false, error: true, errorMessage: res.descrizione });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        let cookies = new Cookies();
        cookies.set('username', this.state.username);
        cookies.set('token', res.token);
        cookies.set('user-type', 'insegnante');
        
        window.location.href = '/#/app/tests';
    }

  render() {
    return (
        <div style={background} onClick={() => (this.props.onClose && !this.state.isLoading) && this.props.onClose()}>
            <div className='login-view' onClick={(e) => e.stopPropagation()}>
                { this.state.isLoading ? <WaveLoading /> :(
                    <div>
                        <h1 style={{ color: '#727272', fontSize: 30, marginTop: 10 }}>{t('login_ins')}</h1>
                        <p style={{ color: '#727272', marginTop: -10 }}>Esegui il login con le tue credenziali scolastiche, se non ne hai, <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => window.location.href = '/#/main/insegnante/registrazione'}>{t('registrati_qui')}</span></p>
                        <br/>
                        { this.state.error && <p style={{ color: 'red', fontWeight: 'bold' }}>{this.state.errorMessage}</p> }
                        <input style={loginInput} placeholder={'Username'} type={'text'} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                        <input style={{ ...loginInput, marginTop: 5 }} placeholder={'Password'} type={'password'} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                        <br/>
                        <div className="btn btn-primary" style={{ marginTop: 40, zIndex: 2 }} onClick={() => this.handleLogin()}>{t('accedi')}</div>
                    </div> 
                )} 
            </div>
        </div>
    )
  }
}

const background = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    bottom: 0, 
    zIndex: 3000,
    height: '100vh', 
    width: '100vw',
    backgroundColor: '#000000B3',
}

const loginInput = {
    width: '80%', 
    height: '50px', 
    backgroundColor: 'white', 
    color: '#727272', 
    borderWidth: '0px',
    borderBottomWidth: '1px', 
    borderBottomColor: '#727272',
}
