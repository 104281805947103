import React, { Component } from "react";
import FilterDomandeComponent from "../components/FilterDomandeComponent";
import ModificaAudioMultipla from "../widgets/Modifiche Domande/ModificaAudioMultipla";
import ModificaAudioVF from "../widgets/Modifiche Domande/ModificaAudioVF";
import ModificaCloze from "../widgets/Modifiche Domande/ModificaCloze";
import ModificaRicostruzione from "../widgets/Modifiche Domande/ModificaRicostruzione";
import ModificaSceltaMultipla from "../widgets/Modifiche Domande/ModificaRispostaMultipla";
import ModificaTestoImmagine from "../widgets/Modifiche Domande/ModificaTestoImmagine";
import ModificaTestoTesto from "../widgets/Modifiche Domande/ModificaTestoTesto";
import ModificaVeroFalso from "../widgets/Modifiche Domande/ModificaVeroFalso";

export default class GestioneDomande extends Component {
    constructor(props) {
        super(props);

        this.filterRef = React.createRef();

        this.state = {
            isLoading: true,
            domande: [],
        };
    }

    async componentDidMount() {

    }

    render() {

        return(
            <div>
                <div className='dashboard-container' style={{ marginBottom: 200 }}>
                    <h3>Gestione domande</h3> 
                    <p>Da questa sezione potrai modificare o eliminare le domande presenti all'interno del portale. Ricorda che le domande eliminate non potranno essere recuperate.</p>

                    <FilterDomandeComponent ref={this.filterRef} onContent={(domande, filtro) => {
                        console.log(domande, filtro);
                        this.filtro = filtro;
                        this.setState({ domande, filtro });
                    }} />

                    { this.state.domande.length === 0 && (
                        <section style={{ width: '100%', height: 200, marginTop: 50, padding: 40, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img alt="No domande" src={require('../images/no_results.png')} style={{ width: 100, height: 100, opacity: 0.6 }} />
                            <p style={{ marginTop: 15, fontWeight: 'bold', color: 'grey', fontSize: 16 }}>Nessuna domanda trovata. Verifica di aver selezionato sia il tipo di test che il tipo di domanda.</p>
                        </section>
                    )}
                    
                    <div style={{ marginTop: 20 }}>
                        { this.state.domande.length > 0 && this.state.domande.map((d) => {
                            if (this.filtro === 0) {
                                return <ModificaVeroFalso key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 1) {
                                return <ModificaSceltaMultipla key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 2) {
                                return <ModificaTestoTesto key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 3) {
                                return <ModificaTestoImmagine key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 4) {
                                return <ModificaAudioVF key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 5) {
                                return <ModificaAudioMultipla key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 6) {
                                return <ModificaCloze key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else if (this.filtro === 7) {
                                return <ModificaRicostruzione key={Math.random()} domanda={d} reload={() => this.filterRef.current.reload()} />
                            } else return <div />
                        })}
                    </div>

                </div>
            </div>
        );
    }
}