import React from 'react';
import { getServiceName } from '../../utils/consts';
import translateString from '../../utils/Locales';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import LoginWidget from '../../widgets/LoginWidget';
import Cookies from 'universal-cookie';
import checkToken from '../../services/checkToken';
import Button from '../../components/Button';
import LoginStudentiWidget from '../../widgets/LoginStudenti';
import checkTokenStudenti from '../../services/checkTokenStudenti';
import checkStudente from '../../services/checkStudente';

let t = translateString;
const cookies = new Cookies();

export default class LandingPage extends React.Component {
 
    constructor(props) {
        super(props);
    
        this.state = {
            loginActive: false,
            logged: false,
            loggedIns: false, 
            loggedStu: false, 
        };
    }

    handleResize() {
        this.setState({ bannerHeight: window.innerHeight });
    }

    handleScroll() {
        if (window.scrollY > 60) {
            document.getElementById('navbar').classList.add('is-sticky');
        } else {
            document.getElementById('navbar').classList.remove('is-sticky');
        }
    }

    async componentDidMount() {

        window.addEventListener('resize', () => this.handleResize());
        window.addEventListener('scroll', () => this.handleScroll());

        this.handleResize();
        this.handleScroll();
        document.title = getServiceName() + ' - Homepage';

        let username = cookies.get('username');
        let token = cookies.get('token');

        if (username && token) {
            let check = await checkToken(username, token);
            if (!check || check.result === 'KO') {
                cookies.remove('username');
                cookies.remove('token');
                return;
            }
            
            let check2 = await checkStudente(username);
            console.log(check2);
            this.setState({ loggedIns: check2.studente === "false", loggedStu: check2.studente === "true" });
        }
        
        this.setState({ });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.handleResize());
        window.removeEventListener('scroll', () => this.handleScroll());
    }

    async handleLogin() {

        if (this.state.loggedIns) {
            window.location.href = "/#/app/tests";
            return;
        }

        this.setState({ loginActive: true });
        return;
    }

    async handleLoginStudenti() {

        if (this.state.loggedStu) {
            window.location.href = '/#/studente/dashboard';
            return;
        }
        
        this.setState({ loginStudentiActive: true });
        return;
    }

    async handlePrivateAccess() {
        
        let username = cookies.get('username');
        let token = cookies.get('token');

        if (!username || !token) {
            cookies.remove('username');
            cookies.remove('token');

            this.setState({ logged: false });
            return;
        }

        let res1 = await checkTokenStudenti(username, token);
        if (res1.result === 'ok') {

            window.location.href = '/#/student/tests';
            return;
        }

        let res2 = await checkToken(username, token);
        if (res2.result === 'ok') {

            window.location.href = '/#/app/tests';
            return;
        }
        
        cookies.remove('username');
        cookies.remove('token');
        this.setState({ logged: false });
    }

    async startTest() {
        
        let username = cookies.get('username');
        let token = cookies.get('token');

        if (!username || !token) {
            cookies.remove('username');
            cookies.remove('token');

            this.setState({ loginStudentiActive: true });
            return;
        }

        let res = await checkTokenStudenti(username, token);
        if (res.result === 'ok') {

            window.location.href = '/#/studente/dashboard';
            return;
        }
        
        cookies.remove('username');
        cookies.remove('token');
        this.setState({ loginStudentiActive: true });
    }

    render () {

        return (
            <div className='mainBody' ref={this.homeRef} style={{ width: '100%', height: '100%', backgroundColor: '#F2F2F2' }}>
                <link rel="stylesheet" href="bootstrap-4.1.1-dist/css/bootstrap.min.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossOrigin="anonymous" />

                <header id='home'>
                <nav id="navbar" className="navbar navbar-expand-lg fixed-top">
                    <div className="container">
                        <img alt="" className='navbar-brand' style={{width: 'auto', height: 80, cursor: 'pointer'}} onClick={() => window.location.href='/#/main/home'} src={require('../../images/icon.png')}/>
                        <h1 style={{ color: '#ff4e57', fontFamily: 'Just Another Hand', fontSize: 50, marginTop: 15 }}></h1>
                        <div style={{ marginBottom: 20 }} className="collapse navbar-collapse" id="navbarNav">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link page-scroll" style={{cursor: 'pointer'}} onClick={() => this.homeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Home</a>
                                </li>
                                { !this.state.logged && (
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link page-scroll" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.handleLogin()} >{this.state.loggedIns ? t('area_r') : t('login_insegnanti')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link page-scroll" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.handleLoginStudenti()} >{this.state.loggedStu ? t('area_r') : t('login_studenti')}</a>
                                        </li>
                                    </>
                                )}
                                { this.state.logged && (
                                    <li className="nav-item">
                                    <a className="nav-link page-scroll" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.handlePrivateAccess()} >{t('area_r')}</a>
                                </li>
                                )}
                            </ul>

                        </div>
                    </div>
                </nav>

                <div className="banner" >
                    <div className="container">
                        <div className="row" style={{ zIndex: 1, display: 'flex', alignItems: 'center' }}>
                            <div className="col-md-8 offset-md-2">
                                <div className='caption' >
                                    <div style={homeView}>
                                        <h1 style={{ color: '#727272', fontSize: 30 }}>CPIA METROPOLITANO DI BOLOGNA<br/>"EDUARD C. LINDEMAN"</h1>
                                        <p style={{ color: '#727272', marginTop: 20 }}>Conoscenze e competenze pregresse</p>
                                        <div style={{ width: 150, marginBottom: 20 }}>
                                            <Button button={"Inizia"} onClick={() => this.startTest()} />
                                        </div>
                                    </div>
                                    <div style={descriptionView}>
                                        <h1 style={{ color: '#727272', fontSize: 30 }}>IL PROGETTO</h1>
                                        <p style={{ color: '#727272', marginTop: 10, textAlign: 'left' }}>Il progetto, realizzato nell’ambito delle attività di ricerca finanziate con DM 48/2021 Art. 3 comma 1 lettera b1, intende:</p>
                                        <ul>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>Favorire la realizzazione e la validazione di strumenti per il riconoscimento delle competenze e degli apprendimenti già in possesso degli studenti che si iscrivono ai percorsi dei CPIA</li>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>Sostenere i docenti che fanno parte delle Commissioni per la definizione del Patto Formativo Individuale dei CPIA e delle scuole con percorsi di secondo livello nelle fasi di identificazione e valutazione</li>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>Predisporre una piattaforma digitale per il self-assessment a disposizione degli studenti.</li>
                                        </ul>
                                        <p style={{ color: '#727272', marginTop: 20, textAlign: 'left' }}>La Piattaforma offre numerosi benefici per i CPIA in quanto:</p>
                                        <ul>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>Gli studenti potranno utilizzare la piattaforma autonomamente o su indicazione dei docenti per verificare competenze e apprendimenti</li>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>Gli esiti delle prove possono essere utilizzati dalle Commissioni per la definizione del PFI come ulteriore evidenza ai fini del riconoscimento dei crediti</li>
                                        </ul>
                                        <p style={{ color: '#727272', marginTop: 20, textAlign: 'left' }}>Inoltre la piattaforma dialoga con il SICPIA</p>
                                        <ul>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>I docenti accedono con le credenziali SICPIA</li>
                                            <li style={{ color: '#727272', marginTop: 5, textAlign: 'left' }}>Gli studenti – se già registrati – accedono con le credenziali SICPIA</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>

                <footer id="contact">
                    <p>&copy; <a target='_blank' style={{ textDecoration: 'none', color: '#5A5A5A', fontWeight: 'bold' }} href="https://www.cpiabologna.edu.it/">C.P.I.A. Metropolitano di Bologna</a> 2022 - {t('diritti')}.</p>
                    <p style={{ marginTop: -10 }}>Cod. Mecc.: BOMM36300D - Cod. Fiscale: 91370230376 - Cod. AOO: A8788B9 - Cod. IPA: cpiabo - Cod. fatturazione: UF6BMZ</p>
                </footer>

                { this.state.loginActive && (
                    <LoginWidget onClose={() => this.setState({ loginActive: false })} />
                )}
                { this.state.loginStudentiActive && (
                    <LoginStudentiWidget onClose={() => this.setState({ loginStudentiActive: false })} />
                )}
            </div>
        );
    }
}

const homeView = {
    width: '100%', 
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex', 
    flexDirection: 'column',
    backgroundColor: 'white', 
    borderRadius: '10px',
    boxShadow: '0px 2px 10px lightgrey', 
    paddingTop: 50, 
    paddingBottom: 20,
    paddingLeft: 30, 
    paddingRight: 30,
}

const descriptionView = {
    width: '100%', 
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex', 
    flexDirection: 'column',
    backgroundColor: 'white', 
    borderRadius: '10px',
    boxShadow: '0px 2px 10px lightgrey', 
    marginTop: 30,
    marginBottom: 20,
    paddingTop: 50, 
    paddingBottom: 20,
    paddingLeft: 50, 
    paddingRight: 50,
}