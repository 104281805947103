import React from "react";
import { Page, Text, View, Image, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import getRisultato from "../services/getRisultato";
import Cookies from "universal-cookie";
import moment from "moment";
import getDomandePerRisultati from "../services/getDomandePerRisultati";
import getTentativiPrecedenti from "../services/getTentativiPrecedenti";

const cookies = new Cookies();

export default class RisultatoTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        }
    }

    async componentDidMount() {

        let username = cookies.get('username');
        let token = cookies.get('token');
        let test = cookies.get('idTest');

        let risultato = await getRisultato(username, token, test);
        if (!risultato) {
            window.location.href = '/#/studente/dashboard';
            return;
        }

        let tentativiPrecedenti = await getTentativiPrecedenti(username, token, risultato.test);

        this.setState({ isLoading: false, risultato, tentativi: tentativiPrecedenti });
    }

    render() {

        if (this.state.isLoading) return <div />;

        const totaleTentativi = this.state.tentativi.superati + this.state.tentativi.non_superati;

        return(
            <PDFViewer style={{ width: '100%', height: '100%' }}>
                <Document title={"Esito test - CPIA - " + cookies.get('idTest')}>
                    <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.logo}>
                            <Text style={{ fontSize: 12, color: 'grey', fontWeight: 'bold' }}>C.P.I.A. Metropolitano di Bologna</Text>
                            <Image source={ require('../images/icon.png') } style={{ width: 30, height: 30, marginLeft: 10, objectFit: 'contain' }} />
                        </View>
                        <View style={styles.studente}>
                            <Text style={{ fontWeight: 'bold' }}>{this.state.risultato.cognome} {this.state.risultato.nome}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Nato a {this.state.risultato.luogo_nascita} ({this.state.risultato.stato_nascita}) il {moment(this.state.risultato.data_nascita, 'yyyy-MM-DD').format('DD/MM/yyyy')}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Codice fiscale: {this.state.risultato.codice_fiscale}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Cittadinanza: {this.state.risultato.cittadinanza}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Residente in {this.state.risultato.citta} ({this.state.risultato.provincia}) all'indirizzo: {this.state.risultato.indirizzo}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Telefono: {this.state.risultato.telefono} - Email: {this.state.risultato.mail}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={{ fontSize: 14 }}>Test numero:    #{cookies.get('idTest')}/{moment(this.state.risultato.iniziato, 'yyyy-MM-DD HH:mm').format('yyyy')} <Text style={{ color: 'grey' }}>{(totaleTentativi > 0) && "- " + totaleTentativi + (totaleTentativi === 1 ? " tentativo, " : " tentativi, ") + this.state.tentativi.superati + (this.state.tentativi.superati === 1 ? " superato" : " superati") }</Text></Text>
                        <Text style={{ fontSize: 14 }}>Iniziato il:         {moment(this.state.risultato.iniziato, 'yyyy-MM-DD HH:mm').format('DD/MM/yyyy')} alle {moment(this.state.risultato.iniziato, 'yyyy-MM-DD HH:mm').format('HH:mm')}</Text>
                        <Text style={{ fontSize: 14 }}>Terminato il:     {moment(this.state.risultato.finito, 'yyyy-MM-DD HH:mm').format('DD/MM/yyyy')} alle {moment(this.state.risultato.finito, 'yyyy-MM-DD HH:mm').format('HH:mm')}</Text>

                        <Text style={{ fontSize: 14, marginTop: 20 }}>Si certifica che lo studente in intestazione ha eseguito il test {this.state.risultato.descrizione} e ha riportato un esito: <Text style={{ color: this.state.risultato.esito <= 11 ? 'red' : 'green' }}>{this.state.risultato.esito <= 15 ? 'NEGATIVO' : 'POSITIVO'}</Text>.</Text>
                        
                        <Text style={{ fontSize: 14, marginTop: 15 }}>Ha infatti ottenuto un punteggio di: <Text style={{ fontSize: 18, marginLeft: 20 }}>{this.state.risultato.esito} / 20 ({parseFloat((parseInt(this.state.risultato.esito)/20) * 100).toFixed(1)} %)</Text>.</Text>

                        <Text style={{ fontSize: 10, marginTop: 200 }}>Il risultato può essere validato inserendo il numero di test sopra riportato nell'apposito gestionale.</Text>
                        
                    </View>
                    </Page>
                </Document>
            </PDFViewer>
        );
    }
}

const styles = StyleSheet.create({
    
    page: {
      flexDirection: 'column',
      backgroundColor: 'white', 
      paddingVertical: 30,
    },

    section: {
      padding: 30,
      flexGrow: 1, 
      paddingHorizontal: 50,
    },

    header: {
        paddingBottom: 40, 
        backgroundColor: '#F0F0F0', 
        boxShadow: '0px 2px 10px lightgrey', 
        width: '100%', 
        marginTop: -30, 
    },

    logo: {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        width: '100%', 
        paddingTop: 30, 
        paddingRight: 30,
    }, 

    studente: {
        paddingLeft: 50, 
        paddingBottom: 20,
        paddingTop: 15,
        width: '100%',
        paddingRight: 50,
    }, 

    checkView: {
      position: 'absolute',
      bottom: 40, 
      left: 40, 
      padding: 20, 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'flex-start', 
      alignItems: 'center', 
    },

  });
  