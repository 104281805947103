import React from 'react';
import Cookies from 'universal-cookie';
import { WaveLoading } from 'react-loadingg';
import { getServiceName } from '../utils/consts';
import getInsegnanti from '../services/getInsegnanti';
import InsegnanteWidget from '../widgets/InsegnanteWidget';
import TextInput from '../components/TextInput';

const cookies = new Cookies();

export default class GestioneInsegnanti extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true, 
            ricerca: '', 
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Insegnanti';

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let insegnanti = await getInsegnanti(username, token);

        this.setState({ isLoading: false, insegnanti });
    }

    render() {

        if (this.state.isLoading) return <WaveLoading />;

        return(
            <div className='dashboard-container' style={{ marginBottom: 200 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <section style={{ width: '100%' }}>
                        <h1>Gestione insegnanti</h1>
                        <p>Da questa pagina è possibile vedere tutti gli insegnanti che hanno partecipato alla creazione di nuove domande per i test. Cliccando su "BLOCCA" all'utente sarà impedito di accedere nuovamente al servizio fino a quando non sarà sbloccato.</p>
                    </section>
                </div>

                { this.state.insegnanti.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: 40, width: '100%' }}>
                        <img src={ require('../images/nothing.jpg') } style={{ height: 200, width: 200, opacity: 0.8 }} />
                        <h4 style={{ color: 'grey', fontWeight: 'bold', opacity: 0.7, textAlign: 'center', width: '100%' }}>Sembra che nessun insegnante abbia ancora creato domande</h4>
                    </div>
                )}

                { this.state.insegnanti.length > 0 && (
                    <>
                        <section style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p><span style={{ fontWeight: 'bold' }}>{this.state.insegnanti.length}</span> {this.state.insegnanti.length === 1 ? 'insegnante' : 'insegnanti'} in totale</p>
                            <TextInput placeholder={'Cerca'} fontSize={12} type={'text'} width={'30%'} style={{ marginBottom: 20 }} onChange={(ricerca) => this.setState({ ricerca })} />
                        </section>
                        { this.state.insegnanti.map((i, ind) => {

                            var show = true; 
                            if (this.state.ricerca.trim()) {
                                if (!i.username.includes(this.state.ricerca)) show = false;
                            }  

                            if (show) return(
                                <InsegnanteWidget key={ind} insegnante={i} />
                            );

                            return <div />;
                        })}
                    </>
                )}
            </div>
        );
    }
}