import React from 'react';

export default class NuovoTestoTesto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            risposte: [], 
            trash: false, 
            deleted: false, 
            error: false, 
        };
    }

    getQuestion() {

        if (this.state.deleted) return { tipo: 'deleted' };

        var allRisposte = true;
        var d = [];
        var risp = [];
        this.state.risposte.forEach((r) => {
            if (!r.risposta || !r.testo) allRisposte = false;

            d.push({ id: r.id, testo: r.testo });
            risp.push({ id: r.id, risposta: r.risposta });
        });

        if (allRisposte && this.state.risposte.length > 0) {

            this.setState({ error: false });
            
            var res = {
                tipo: 2, 
                domande: d, 
                risposte: risp, 
            }
            return res;
        }

        this.setState({ error: true });
    }

    delete() {
        this.setState({ deleted: true });
    }

    setPrincipale(id, text) {
        
        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.testo = text;
            }
        });

        this.setState({ risposte });
    }

    setAssociato(id, text) {

        var risposte = this.state.risposte;
        risposte.forEach((r) => {
            if (r.id === id) {
                r.risposta = text;
            }
        });

        this.setState({ risposte });
    }
    
    addRisposta() {
        let risposte = this.state.risposte;
        risposte.push({ id: this.state.risposte.length + 1, testo: '', risposta: '' });
        this.setState({ risposte });
    }

    render() {

        if (this.state.deleted) return <div />;

        return(
            <section className='question-container'>
                { this.state.error && ( <p style={{ color: '#C13F3F', fontWeight: 'bold' }}>Verifica di aver inserito tutti i dati in questa domanda.</p> )}
                <p style={{ color: '#818181', marginTop: 30, fontWeight: 'bold', marginBottom: 30 }}>Inserisci fino a 5 testi con il relativo corrispondente, le risposte saranno poi automaticamente mescolate in fase di presentazione allo studente.</p>
                <div className='answer-container' style={{ flexDirection: 'column' }}>
                    { this.state.risposte.map((risposta) => {

                        return(
                            <section key={risposta.id} className='answer answer-selectable' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>
                                <textarea style={{ resize: 'none', minHeight: 60, maxHeight: 150, width: '100%', border: '1px solid #F3F3F3', marginRight: -1, padding: 20, }} placeholder={'Testo principale'} onChange={(e) => this.setPrincipale(risposta.id, e.target.value)} />
                                <textarea style={{ resize: 'none', minHeight: 60, maxHeight: 150, width: '100%', border: '1px solid #F3F3F3', padding: 20 }} placeholder={'Testo associato'} onChange={(e) => this.setAssociato(risposta.id, e.target.value)} />
                            </section>
                        );
                    })}
                    { this.state.risposte.length < 5 && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer', marginTop: 20 }} onClick={() => this.addRisposta()}>
                            <img src={ require('../images/add.png') } style={{ width: 20, height: 20 }} />
                            <span style={{ marginLeft: 10 }}>Aggiungi nuovo testo</span>
                        </div>
                    )}
                    { this.state.risposte.length >= 5  && (
                        <span style={{ marginLeft: 10, marginTop: 20, color: 'grey' }}>Hai raggiunto il limite di 5 testi</span>
                    )}
                </div>
                <img src={ require('../images/trash.png') } style={{ position: 'absolute', right: 20, bottom: 20, width: 20, height: 20, cursor: 'pointer', opacity: this.state.trash ? 1 : 0.6 }} onMouseEnter={() => this.setState({ trash: true })} onMouseLeave={() => this.setState({ trash: false })} onClick={() => {
                    if (window.confirm('Vuoi davvero eliminare questa domanda?')) {
                        this.setState({ deleted: true });
                    }
                }} />
            </section>
        );
    }
}