import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../../components/Button';
import SelectInput from '../../components/SelectInput';
import translateString from '../../utils/Locales';
import stati from '../../utils/countries.json';
import italia from '../../utils/regioni.json';
import checkSeUtente from '../../services/checkSeUtente';
import registraStudente from '../../services/registraStudente';

let t = translateString;
const cookies = new Cookies();

export default class RegistrazioneStudente extends React.Component {
    constructor(props) {
        super(props);

        this.generi = [
            { value: 'M', label: 'Maschio' }, 
            { value: 'F', label: 'Femmina' }
        ];

        this.stati = [];
        stati.forEach((s) =>{
            this.stati.push({ value: s.code, label: s.name });
        });

        this.province = [];
        italia['regioni'].forEach((r) => {
            italia[r].forEach((p) => {
                this.province.push({ value: p.sigla, label: p.nome });
            });
        });

        this.province.sort((p1, p2) => p1.label.localeCompare(p2.label));

        this.titoli = [
            { value: 1, label: 'Laurea o superiore' },
            { value: 2, label: 'Laurea triennale' }, 
            { value: 3, label: 'Diploma di scuola secondaria' }, 
            { value: 4, label: 'Qualifica professionale' }, 
            { value: 5, label: 'Diploma di licenzia media' },
            { value: 6, label: 'Diploma di licenza elementare' }, 
            { value: 7, label: 'Nessun titolo' }
        ];

        this.professioni = [
            { value: 1, label: 'Disoccupato/a' },
            { value: 2, label: 'In cerca di prima occupazione' }, 
            { value: 3, label: 'In cerca di nuova occupazione' }, 
            { value: 4, label: 'Lavoratore dipendente' }, 
            { value: 5, label: 'Lavoratore autonomo' },
            { value: 6, label: 'Pensionato/a' }
        ];

        this.state = {
            nome: '', 
            cognome: '', 
            genere: { value: undefined, label: '*Genere...'},
            cittadinanza: { value: undefined, label: '*Cittadinanza...'}, 
            stato_nascita: { value: undefined, label: '*Stato di nascita...' },
            luogo_nascita: '', 
            codice_fiscale: '',
            indirizzo: '', 
            citta: '',
            scolarita: undefined, 
            provincia: { value: undefined, label: '*Provincia di residenza...'}, 
            titolo: { value: undefined, label: '*Titolo di studio...' },
            professione: { value: undefined, label: '*Professione...' },
            mail: '',
            note: '', 
            telefono: null,
            isUser: false,
            token: '',
            username: '', 
            password: '',
        };
    }

    componentDidMount() {
        let utente = cookies.get('new_username');
        let password = cookies.get('new_password');
        if (!utente || !password) return;
        
        this.setState({
            username: utente, 
            password: password,
            isUser: true
        });
    }

    componentWillUnmount() {
        cookies.remove('new_username');
        cookies.remove('new_password');
    }

    showError(message) {

        this.setState({ error: true, errorMessage: message });
        setTimeout(() => {
            this.setState({ error: false, errorMessage: '' });
        }, 5000);
    }

    async crea() {

        // CONTROLLI
        if (!this.state.nome) return this.showError('Non è stato inserito il nome.');
        if (!this.state.cognome) return this.showError('Non è stato inserito il cognome.');
        if (!this.state.genere.value) return this.showError('Non è stato specificato il genere.');
        if (!this.state.data_nascita) return this.showError('Non è stata inserita la data di nascita.');
        if (!this.state.stato_nascita.value) return this.showError('Non è stato specificato lo Stato di nascita.');
        if (!this.state.cittadinanza.value) return this.showError('Non è stata specificata la cittadinanza.');
        if (!this.state.luogo_nascita) return this.showError('Non è stato inserito il luogo di nascita.');
        if (this.state.codice_fiscale && this.state.codice_fiscale.length != 16) return this.showError('Il codice fiscale inserito non risulta valido.');
        if (!this.state.indirizzo) return this.showError('Non è stato inserito l\'indirizzo di residenza.');
        if (!this.state.citta) return this.showError('Non è stata inserita la città di residenza.');
        if (!this.state.provincia.value) return this.showError('Non è stata specificata la provincia di residenza.');
        if (!this.state.titolo.value) return this.showError('Non è stato specificato un titolo di studio.');
        if (!this.state.professione.value) return this.showError('Non è stata specificata una professione.');
        if (!this.state.scolarita) return this.showError('Non sono stati inseriti gli anni di scuola frequentati.');
        if (!this.state.mail) return this.showError('Non è stato inserito un indirizzo email.');
        if (!this.state.username) return this.showError('Non è stato inserito un nome utente valido.');
        if (!this.state.password) return this.showError('Non è stata inserita una password valida.');
        if (this.state.note.length > 500) return this.showError('Il campo "Informazioni utili" non può contenere più di 500 caratteri.');
       

        // CONTROLLO USERNAME
        let res = await checkSeUtente(this.state.username);
        if (!res || res.result === 'ok') return this.showError('Lo username inserito risulta già presente nel nostro sistema. Per favore, inseriscine un altro.');

        // REGISTRAZIONE UTENTE
        let utente = {
            nome: this.state.nome, 
            cognome: this.state.cognome, 
            genere: this.state.genere.value, 
            data_nascita: this.state.data_nascita,
            stato_nascita: this.state.stato_nascita.value, 
            cittadinanza: this.state.cittadinanza.value, 
            luogo_nascita: this.state.luogo_nascita, 
            codice_fiscale: this.state.codice_fiscale, 
            indirizzo: this.state.indirizzo, 
            citta: this.state.citta, 
            provincia: this.state.provincia.value, 
            titolo: this.state.titolo.value, 
            occupazione: this.state.professione.value, 
            anni_scolarita: this.state.scolarita, 
            mail: this.state.mail, 
            telefono: this.state.telefono, 
            username: this.state.username, 
            password: this.state.password, 
            note: this.state.note, 
            with_token: this.state.isUser ? 1 : 0,
            utente_scuola: this.state.isUser ? 1 : 0, 
            token: this.state.token
        }

        let res1 = await registraStudente(utente);
        if (!res1 || res1.result === 'KO') return this.showError('Si è verificato un errore imprevisto. Per favore, riprova.');

        alert('Utente creato con successo. Esegui il login con le credenziali appena create per continuare');
        window.location.href = '/#/main/home';
    }

    render() {

        return(
            <div className='mainBody' ref={this.homeRef} style={{ width: '100%', height: '100%', backgroundColor: '#F2F2F2', color: 'grey' }}>
                <link rel="stylesheet" href="bootstrap-4.1.1-dist/css/bootstrap.min.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossOrigin="anonymous" />

                <header id='home'>
                <nav id="navbar" className="navbar is-sticky navbar-expand-lg fixed-top">
                    <div className="container">
                        <img alt="" className='navbar-brand' style={{width: 'auto', height: 80, cursor: 'pointer'}} onClick={() => window.location.href='/#/main/home'} src={require('../../images/icon.png')}/>
                        <h1 style={{ color: '#ff4e57', fontFamily: 'Just Another Hand', fontSize: 50, marginTop: 15 }}></h1>
                        <div className="collapse navbar-collapse" id="navbarNav">

                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link page-scroll" style={{cursor: 'pointer'}} onClick={() => window.location.href = '/#/main/home'}>Home</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>

                <div className='dashboard-container' style={{ marginBottom: 200, marginTop: 200 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                        <section style={{ width: '80%', color: 'grey' }}>
                            <h1>Ciao</h1>
                            <p>Crea qui il tuo account per poter accedere ai test.</p>
                        </section>
                        <section style={{ marginLeft: 30 }}>
                            <Button button={'SALVA E REGISTRATI'} onClick={ () => this.crea() } />
                        </section>
                    </div>

                    { this.state.error && (
                        <p style={{ color: '#8B3015', fontWeight: 'bold', marginTop: -25  }}>{this.state.errorMessage}</p>
                    )}

                    Anagrafica
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <input className='reg-input' type={'text'} value={this.state.nome} placeholder="Nome *" onChange={(e) => this.setState({ nome: e.target.value })} />
                        <input className='reg-input second' type={'text'} value={this.state.cognome} placeholder="Cognome *" onChange={(e) => this.setState({ cognome: e.target.value })} />
                    </section>
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <SelectInput
                            value={this.state.genere}
                            reg={true} 
                            hidable={false}
                            isMulti={false}
                            options={this.generi} 
                            onChange={(option) => 
                                this.setState({ genere: option })} 
                        />
                        <input className='reg-input second' type={ this.state.onDate ? 'date' : 'text'} value={this.state.data_nascita} placeholder="Data di nascita *" onFocus={() => this.setState({ onDate: true })} onChange={(e) => this.setState({ data_nascita: e.target.value })} />
                    </section>
                    <section className='horizontal-s' style={{ marginTop: 5 }}>
                        <SelectInput
                            value={this.state.stato_nascita}
                            reg={true} 
                            hidable={false}
                            isMulti={false}
                            options={this.stati} 
                            onChange={(option) => 
                                this.setState({ stato_nascita: option })} 
                        />
                        <section style={{ width: 7, height: 10 }} />
                        <SelectInput
                            value={this.state.cittadinanza}
                            reg={true} 
                            hidable={false}
                            isMulti={false}
                            options={this.stati} 
                            onChange={(option) => 
                                this.setState({ cittadinanza: option })} 
                        />
                    </section>
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <input className='reg-input' type={'text'} value={this.state.luogo_nascita} placeholder="Luogo di nascita *" onChange={(e) => this.setState({ luogo_nascita: e.target.value })} />
                        <input className='reg-input second' type={'text'} value={this.state.codice_fiscale} placeholder="Codice Fiscale" onChange={(e) => this.setState({ codice_fiscale: e.target.value.toUpperCase() })} />
                    </section>

                    <br/><br/>Residenza
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <input className='reg-input' type={'text'} value={this.state.indirizzo} placeholder="Indirizzo di residenza *" onChange={(e) => this.setState({ indirizzo: e.target.value })} required />
                        <input className='reg-input second' type={'text'} value={this.state.citta} placeholder="Città *" onChange={(e) => this.setState({ citta: e.target.value })} required />                        
                    </section>
                    <section style={{ marginTop: 10 }}>
                        <SelectInput
                            value={this.state.provincia}
                            reg={true} 
                            hidable={false}
                            isMulti={false}
                            options={this.province} 
                            onChange={(option) => 
                                this.setState({ provincia: option })} 
                        />
                    </section>

                    <br/><br/>Istruzione e lavoro
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <SelectInput
                            value={this.state.titolo}
                            reg={true} 
                            hidable={false}
                            isMulti={false}
                            options={this.titoli} 
                            onChange={(option) => 
                                this.setState({ titolo: option })} 
                        />
                        <section style={{ width: 7, height: 10 }} />
                        <SelectInput
                            value={this.state.professione}
                            reg={true} 
                            hidable={false}
                            isMulti={false}
                            options={this.professioni} 
                            onChange={(option) => 
                                this.setState({ professione: option })} 
                        />
                    </section>
                    <section style={{ marginTop: 10 }}>
                        <input className='reg-input second' type={'number'} value={this.state.scolarita} placeholder="Anni di scuola *" onChange={(e) => this.setState({ scolarita: e.target.value })} required />                        
                    </section>

                    <br/><br/>Contatti
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <input className='reg-input' type={'email'} value={this.state.mail} placeholder="Email *" onChange={(e) => this.setState({ mail: e.target.value })} required />
                        <input className='reg-input second' type={'tel'} value={this.state.telefono} placeholder="Telefono" onChange={(e) => this.setState({ telefono: e.target.value })} required />                        
                    </section>

                    { !this.state.isUser && (
                        <>
                            <br/><br/>Credenziali
                            <section className='horizontal-s' style={{ marginTop: 10 }}>
                                <input className='reg-input' type={'text'} readOnly={this.state.isUser} value={this.state.username} placeholder="Username *" onChange={(e) => this.setState({ username: e.target.value })} required />
                                <section style={{ position: 'relative' }}>
                                    <input className='reg-input second' readOnly={this.state.isUser} style={{ paddingRight: 40 }} type={this.state.showPassword ? 'text' : 'password'} value={this.state.password} placeholder="Password *" onChange={(e) => this.setState({ password: e.target.value })} required />                        
                                    <img style={{ position: 'absolute', right: 15, top: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} alt="Vedi" src={ this.state.showPassword ? require('../../images/showpass.png') : require('../../images/hidepass.png') } />
                                </section>
                            </section>
                        </>
                    )}

                    <br/><br/>Informazioni utili
                    <section className='horizontal-s' style={{ marginTop: 10 }}>
                        <textarea style={{ resize: 'none', width: '100%', height: 100, color: 'grey', border: '1px solid lightgrey', borderRadius: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20 }} placeholder="Inserisci, se vuoi, altre informazioni utili su di te..." maxLength={500} value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} />                        
                    </section>
                </div>
                </header>


                <footer id="contact">
                    <p>&copy; <a target='_blank' style={{ textDecoration: 'none', color: '#5A5A5A', fontWeight: 'bold' }} href="https://www.cpiabologna.edu.it/">C.P.I.A. Metropolitano di Bologna</a> 2022 - {t('diritti')}.</p>
                    <p style={{ marginTop: -10 }}>Cod. Mecc.: BOMM36300D - Cod. Fiscale: 91370230376 - Cod. AOO: A8788B9 - Cod. IPA: cpiabo - Cod. fatturazione: UF6BMZ</p>
                </footer>
            </div>
        );
    }
}