import React from 'react'
import { useDrag } from 'react-dnd'

function DragImage({ id, immagine }) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "image", 
        item: { id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        }),
    }))

  return (
    <img ref={drag} src={immagine} width={150} style={{ objectFit: 'contain' }} />
  )
}

export default DragImage