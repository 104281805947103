import React from "react";
import { WaveLoading } from 'react-loadingg';
import Cookies from "universal-cookie";
import getAllDomande from "../services/getAllDomande";
import getAllTests from "../services/getAllTests";
import { TiTick } from 'react-icons/ti';
import { GiChoice } from 'react-icons/gi';
import { BsImages, BsInputCursorText, BsSortNumericDown } from 'react-icons/bs';
import { BiText } from 'react-icons/bi';
import { AiTwotoneAudio, AiFillCheckCircle } from 'react-icons/ai';
import getStatisticheTests from "../services/getStatisticheTests";
import FilterComponent from "../components/FilterComponent";
import Table from '../widgets/tables/NewTable';

const cookies = new Cookies();

export default class Statistiche extends React.Component {
    constructor(props) {
        super(props);

        this.stats = [0, 0, 0, 0, 0, 0, 0, 0, 0];

        this.state = {
            isLoading: true,
            tentativi: [], 
        };
    }

    async componentDidMount() {

        let username = cookies.get('ad_username');
        let token = cookies.get('ad_token');
        let tests = await getAllTests(username, token);
        let domande = await getAllDomande(username, token);
        let contiEsiti = await getStatisticheTests(username, token);
        
        var stats = [];
        var shows = [];

        tests.forEach((test, ind) => {

            var s = [0, 0, 0, 0, 0, 0, 0, 0, 0];
            shows.push(false);

            let ds = domande.filter((d) => d.test === test.id);
            ds.forEach((d) => {
                s[parseInt(d.tipo)] = s[parseInt(d.tipo)] + 1;
            });

            stats.push(s);
        });
        
        this.setState({ tests, stats, show: shows, contiEsiti, isLoading: false });
    }

    render() {

        if (this.state.isLoading) return <WaveLoading />;

        var headCells = [
            { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
            { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
            { id: 'descrizione', numeric: false, disablePadding: false, label: 'Test' },
            { id: 'esito', numeric: true, disablePadding: false, label: 'Esito' },
        ];

        this.searchCols = ['nome', 'cognome'];

        return(
            <>
                <div className='dashboard-container' style={{ marginBottom: 200 }}>
                    <h3>Statistiche</h3> 
                    <p>Qui potrai trovare alcune statistiche sui test disponibili all'interno del sistema e sui risultati ottenuti dagli studenti che hanno effettuato dei tentativi.</p>

                    <h5 style={{ marginTop: 40 }}>Contenuto tests:</h5>
                    { this.state.tests.map((t, ind) => {

                        return(
                            <div style={{ marginTop: 15 }}>
                                <h6 style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => {
                                    var s = this.state.show;
                                    s[ind] = !s[ind];
                                    this.setState({ show: s });
                                }}>{t.descrizione}</h6>
                                { this.state.show[ind] && (
                                    <div style={{ marginLeft: 15, marginTop: 15 }}>
                                        <StatView icon={<TiTick />} descrizione={ this.state.stats[parseInt(t.id) - 1][0] + " vero o falso"} />
                                        <StatView icon={<GiChoice />} descrizione={ this.state.stats[parseInt(t.id) - 1][1] + " risposta multipla"} />
                                        <StatView icon={<BiText />} descrizione={ this.state.stats[parseInt(t.id) - 1][2] + " abbinamento testo/testo"} />
                                        <StatView icon={<BsImages />} descrizione={ this.state.stats[parseInt(t.id) - 1][3] + " abbinamento testo/immagine"} />
                                        <StatView icon={<AiTwotoneAudio />} descrizione={ this.state.stats[parseInt(t.id) - 1][4] + " audio vero/falso"} />
                                        <StatView icon={<AiTwotoneAudio />} descrizione={ this.state.stats[parseInt(t.id) - 1][5] + " audio scelta multipla"} />
                                        <StatView icon={<BsInputCursorText />} descrizione={ this.state.stats[parseInt(t.id) - 1][6] + " cloze"} />
                                        <StatView icon={<BsSortNumericDown />} descrizione={ this.state.stats[parseInt(t.id) - 1][7] + " riorganizzazione concettuale"} />
                                        <StatView icon={<AiFillCheckCircle />} descrizione={ this.state.stats[parseInt(t.id) - 1][8] + " completa l'affermazione"} />
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <h5 style={{ marginTop: 40 }}>Esiti:</h5>
                    <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: -5 }}>
                        <section style={{ width: '100%', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, marginLeft: 5, marginRight: 5, backgroundColor: '#EEEEEE', borderRadius: 10 }}>
                            <h5>Test iniziati</h5>
                            <h2>{this.state.contiEsiti.iniziati}</h2>
                        </section>
                        <section style={{ width: '100%', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, marginLeft: 5, marginRight: 5, backgroundColor: '#EEEEEE', borderRadius: 10 }}>
                            <h5>Test consegnati</h5>
                            <h2>{this.state.contiEsiti.consegnati}</h2>
                        </section>
                        <section style={{ width: '100%', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, marginLeft: 5, marginRight: 5, backgroundColor: '#EEEEEE', borderRadius: 10 }}>
                            <h5>Test superati</h5>
                            <h2>{this.state.contiEsiti.passati}</h2>
                        </section>
                    </section>

                    <section style={{ marginTop: 20 }}>
                        <FilterComponent tests={this.state.tests} onContent={(tentativi) => {
                            this.setState({ tentativi });
                        }} />
                    </section>
                    
                    <div style={{ marginTop: 40 }}>
                        <Table headCells={headCells} searchCols={this.searchCols} calendar={false} pdf={false} excel={false} mail={false} phone={false} rows={this.state.tentativi}
        
                            onEventClick={(row) => {
                            cookies.set('currentStudente', row, { path: '/' });
                            window.location.href = '/' + this.dbPath + '/#/app/editStudente';
                            }}

                        />
                    </div>
     
                </div>  
            </>
        );
    }
}

const StatView = (props) => {

    return(
        <section style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: -15 }}>
            {props.icon}
            <p style={{ marginLeft: 10, marginTop: 15 }}>{props.descrizione}</p>
        </section>
    );
}
