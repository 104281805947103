import React from "react";
import { Page, Text, View, Image, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import getRisultato from "../services/getRisultato";
import Cookies from "universal-cookie";
import moment from "moment";
import getDomandePerRisultati from "../services/getDomandePerRisultati";
import getTentativiPrecedenti from "../services/getTentativiPrecedenti";
import { BsFilePdfFill } from "react-icons/bs";

const cookies = new Cookies();

export default class PaginaRisultato extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        }
    }

    async componentDidMount() {

        let username = cookies.get('username');
        let token = cookies.get('token');
        let test = cookies.get('idTest');

        let risultato = await getRisultato(username, token, test);
        if (!risultato) {
            window.location.href = '/#/studente/dashboard';
            return;
        }

        let tentativiPrecedenti = await getTentativiPrecedenti(username, token, risultato.test);

        let domande = await getDomandePerRisultati(username, token, risultato.test);
        risultato.domande_risposte = JSON.parse(risultato.domande_risposte);

        var risultati = [];
        var all = true;
        risultato.domande_risposte.forEach((r) => {
            var obj = {};
            let domanda = domande.find(d => d.id === r.id);
            if (!domanda) {
                all = false;
                return;
            }
            if (domanda.tipo === '0' || domanda.tipo === '4') {

                // Vero o falso
                obj.domanda = domanda.domanda;
                obj.risposta_data = r.risposta === 1 ? 'Vero' : r.risposta === 2 ? 'Falso' : '-';
                obj.risposta_corretta = domanda.risposta_corretta === 1 ? 'Vero' : 'Falso';
                risultati.push(obj);
            } else if (domanda.tipo === '1' || domanda.tipo === '5' || domanda.tipo === '8') {

                // Scelta multipla
                obj.domanda = domanda.domanda;
                let risposte = JSON.parse(domanda.risposte);
                obj.risposta_data = r.risposta === 0 ? '-' : risposte.find(risp => risp.id === parseInt(r.risposta)).risposta;
                obj.risposta_corretta = risposte.find(risp => risp.id === parseInt(domanda.risposta_corretta)).risposta;
                risultati.push(obj);
            } else if (domanda.tipo === '2' || domanda.tipo === '3') {

                // Abbinamenti testo-testo e testo-immagine
                obj.domanda = domanda.tipo === '2' ? "Abbinamento Testo-Testo" : "Abbinamento Testo-Immagine";
                let risposte = JSON.parse(domanda.risposte);
                let risposteLength = risposte.length;
                let rispostaData = JSON.parse(r.risposta);

                if (!rispostaData || (rispostaData.length !== risposteLength)) {
                    obj.risposta_data = "non correttamente (non hai abbinato tutti i testi presenti).";
                } else {

                    var risp = [];
                    risposte.forEach((ri) => {
                        risp.push(ri.id);
                    });
                    
                    let correct = true;
                    rispostaData.forEach((val, ind) => {
                        if (!risp[ind] === val) correct = false;
                    });
                    obj.risposta_data = correct ? "correttamente." : "non correttamente.";
                }

                obj.no_risp = 1;
                risultati.push(obj);

            } else if (domanda.tipo === '6' || domanda.tipo === '7') {

                // Cloze
                obj.domanda = domanda.tipo === '6' ? "Completamento di un testo (Cloze)" : "Riordinamento cronologico";
                let risposte = JSON.parse(domanda.risposte);
                let risposteLength = risposte.length;
                let rispostaData = JSON.parse(r.risposta);
                
                if (!rispostaData || (rispostaData.length !== risposteLength)) {
                    obj.risposta_data = domanda.tipo === '6' ? "non correttamente (non hai inserito tutte le parole mancanti)." : "non correttamente.";
                } else {

                    var risp = [];
                    risposte.forEach((ri) => {
                        risp.push(ri.id);
                    });
                    
                    let correct = true;
                    rispostaData.forEach((val, ind) => {
                        if (!risp[ind] === val) correct = false;
                    });
                    obj.risposta_data = correct ? "correttamente." : "non correttamente.";
                }

                obj.no_risp = 1;
                risultati.push(obj);
            }
        });

        this.setState({ isLoading: false, risultato, risposte: risultati, tentativi: tentativiPrecedenti, all });
    }

    render() {

        if (this.state.isLoading) return <div />;

        const totaleTentativi = this.state.tentativi.superati + this.state.tentativi.non_superati;

        return(
            <div>
                <section className="dashboard-container risultato">
                    <h4 style={{ color: this.state.risultato.esito <= 11 ? 'red' : 'green' }}>{this.state.risultato.esito <= 11 ? "Non hai superato questo test..." : "Hai superato con successo questo test!"}</h4>
                    <h6>Hai ottenuto un punteggio di:</h6>
                </section>
                <section className="risultato-container">
                    <section className="risultato-view">
                        <p style={{ marginTop: 10 }}><span style={{ fontSize: 60, fontWeight: 'bold' }}>{this.state.risultato.esito}</span> <span style={{ fontSize: 18 }}>/ 20</span></p>
                    </section>
                </section>
                <section className="btn-container-risultato">
                    <section className="btn-left">
                        <div className="pdf-btn" onClick={() => window.location.href = '/#/studente/pdf'}>
                            <BsFilePdfFill size={15} />
                            <span style={{ marginLeft: 10 }}>Scarica il PDF</span>
                        </div>
                    </section>
                    <section className="btn-left">

                    </section>
                </section>

                <section className="mobile-padding">
                    <h5 className="margin" style={{ marginBottom: 20 }}>Le tue domande e risposte:</h5>
                    { this.state.risposte.map((r) => (
                        <div className="risposta-view">
                            <h6 style={{ fontSize: 12, marginTop: 5, marginBottom: 5 }}>{r.domanda}</h6>
                            <p style={{ fontSize: 12, color: r.no_risp ? r.risposta_data.includes('non correttamente') ? 'red' : 'black' : r.risposta_data === r.risposta_corretta ? 'black' : 'red', marginTop: 10, marginBottom: 0 }}>Hai risposto: {r.risposta_data}</p>
                            { r.risposta_corretta && ( <p style={{ fontSize: 12 }}>Risposta corretta: {r.risposta_corretta}</p> )}
                                        
                        </div>
                    ))}
                    { !this.state.all && (<p style={{ fontSize: 12, color: 'grey', marginTop: 10, marginLeft: '10%' }}>Alcune domande non sono state trovate e non sono disponibili.</p>)}
                </section>
                
            { false && (
            <PDFViewer style={{ width: '100%', height: '100%' }}>
                <Document title={"Esito test - CPIA - " + cookies.get('idTest')}>
                    <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.logo}>
                            <Text style={{ fontSize: 12, color: 'grey', fontWeight: 'bold' }}>C.P.I.A. Metropolitano di Bologna</Text>
                            <Image source={ require('../images/icon.png') } style={{ width: 30, height: 30, marginLeft: 10, objectFit: 'contain' }} />
                        </View>
                        <View style={styles.studente}>
                            <Text style={{ fontWeight: 'bold' }}>{this.state.risultato.cognome} {this.state.risultato.nome}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Nato a {this.state.risultato.luogo_nascita} ({this.state.risultato.stato_nascita}) il {moment(this.state.risultato.data_nascita, 'yyyy-MM-DD').format('DD/MM/yyyy')}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Codice fiscale: {this.state.risultato.codice_fiscale}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Cittadinanza: {this.state.risultato.cittadinanza}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Residente in {this.state.risultato.citta} ({this.state.risultato.provincia}) all'indirizzo: {this.state.risultato.indirizzo}</Text>
                            <Text style={{ fontSize: 13, color: 'grey', marginTop: 10 }}>Telefono: {this.state.risultato.telefono} - Email: {this.state.risultato.mail}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={{ fontSize: 14 }}>Test numero:    #{cookies.get('idTest')}/{moment(this.state.risultato.iniziato, 'yyyy-MM-DD HH:mm').format('yyyy')} <Text style={{ color: 'grey' }}>{(totaleTentativi > 0) && "- " + totaleTentativi + (totaleTentativi === 1 ? " tentativo, " : " tentativi, ") + this.state.tentativi.superati + (this.state.tentativi.superati === 1 ? " superato" : " superati") }</Text></Text>
                        <Text style={{ fontSize: 14 }}>Iniziato il:         {moment(this.state.risultato.iniziato, 'yyyy-MM-DD HH:mm').format('DD/MM/yyyy')} alle {moment(this.state.risultato.iniziato, 'yyyy-MM-DD HH:mm').format('HH:mm')}</Text>
                        <Text style={{ fontSize: 14 }}>Terminato il:     {moment(this.state.risultato.finito, 'yyyy-MM-DD HH:mm').format('DD/MM/yyyy')} alle {moment(this.state.risultato.finito, 'yyyy-MM-DD HH:mm').format('HH:mm')}</Text>

                        <Text style={{ fontSize: 14, marginTop: 20 }}>Si certifica che lo studente in intestazione ha eseguito il test {this.state.risultato.descrizione} e ha riportato un esito: <Text style={{ color: this.state.risultato.esito <= 11 ? 'red' : 'green' }}>{this.state.risultato.esito <= 15 ? 'NEGATIVO' : 'POSITIVO'}</Text>.</Text>
                        
                        <Text style={{ fontSize: 14, marginTop: 15 }}>Ha infatti ottenuto un punteggio di: <Text style={{ fontSize: 18, marginLeft: 20 }}>{this.state.risultato.esito} / 20 ({parseFloat((parseInt(this.state.risultato.esito)/20) * 100).toFixed(1)} %)</Text>.</Text>


                        <View style={{ marginTop: 50 }}>
                            <Text>Le tue domande e risposte</Text>
                            <View style={{ marginTop: 10 }}>
                                { this.state.risposte.map((r, ind) => {

                                    return(
                                        <View key={ind} style={{ marginTop: -1, width: '100%', padding: 20, borderColor: 'lightgrey', borderWidth: 1,  }}>
                                            <Text style={{ fontSize: 12 }}>{r.domanda}</Text>
                                            <Text style={{ fontSize: 12, color: r.no_risp ? r.risposta_data.includes('non correttamente') ? 'red' : 'black' : r.risposta_data === r.risposta_corretta ? 'black' : 'red' }}>Hai risposto: {r.risposta_data}</Text>
                                            { r.risposta_corretta && ( <Text style={{ fontSize: 12 }}>Risposta corretta: {r.risposta_corretta}</Text> )}
                                        </View>
                                    );
                                })}
                                
                            </View>
                        </View>
                        <Text style={{ fontSize: 10, marginTop: 20 }}>Il risultato può essere validato inserendo il numero di test sopra riportato nell'apposito gestionale.</Text>
                        
                    </View>
                    </Page>
                </Document>
            </PDFViewer>
            )}
            </div>
        );
    }
}

const styles = StyleSheet.create({
    
    page: {
      flexDirection: 'column',
      backgroundColor: 'white', 
      paddingVertical: 30,
    },

    section: {
      padding: 30,
      flexGrow: 1, 
      paddingHorizontal: 50,
    },

    header: {
        paddingBottom: 40, 
        backgroundColor: '#F0F0F0', 
        boxShadow: '0px 2px 10px lightgrey', 
        width: '100%', 
        marginTop: -30, 
    },

    logo: {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        width: '100%', 
        paddingTop: 30, 
        paddingRight: 30,
    }, 

    studente: {
        paddingLeft: 50, 
        paddingBottom: 20,
        paddingTop: 15,
        width: '100%',
        paddingRight: 50,
    }, 

    checkView: {
      position: 'absolute',
      bottom: 40, 
      left: 40, 
      padding: 20, 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'flex-start', 
      alignItems: 'center', 
    },

  });
  