import React from "react";
import Cookies from 'universal-cookie';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
      case "LOGIN_FAILURE":
        return { ...state, isAuthenticated: false };
      case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(dispatch, login, password, db, history, setIsLoading, setError) {
  
      dispatch({ type: 'LOGIN_SUCCESS' });
      dispatch({ type: "LOGIN_FAILURE" });
}
 
function signOut(dispatch, history) {
  const cookies = new Cookies();
  cookies.set('token', '', { path: '/' });
  cookies.set('currentEventName', '', { path: '/' });
  cookies.set('mode', '', { path: '/' });
  cookies.set('username', '', { path: '/' });
  window.sessionStorage.setItem('user_type', '');
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  window.location.href = '/' + cookies.get('db', { path: '/' }) + '#/login';
}
