import React from 'react';
import { WaveLoading } from 'react-loadingg';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import correggiTest from '../services/correggiTest';
import getDomande from '../services/getDomande';
import AudioSceltaMultipla from '../widgets/AudioSceltaMultipla';
import AudioVeroFalso from '../widgets/AudioVeroFalso';
import Cloze from '../widgets/Cloze';
import Completamento from '../widgets/Completamento';
import ConsegnaTest from '../widgets/ConsegnaTest';
import RiorganizzazioneConcettuale from '../widgets/RiorganizzazioneConcettuale';
import SceltaMultipla from '../widgets/SceltaMultipla';
import TestoImmagine from '../widgets/TestoImmagine';
import TestoTesto from '../widgets/TestoTesto';
import VeroFalso from '../widgets/VeroFalso';

const cookies = new Cookies();
var countDown = 1200000;

export default class Test extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true, 
            domande: [],
            refs: [],
            timer: '',
            inConsegna: false,
            correctionDone: false,
        };
    }

    async componentDidMount() {

        let username = cookies.get('username');
        let token = cookies.get('token');
        let id = cookies.get('currentTest');
       
        let domande = await getDomande(username, token, parseInt(id));

        if (!id) {
            window.location.href = '/#/studente/dashboard';
            return;
        }

        var refs = [];
        domande.forEach(() => {
            refs.push(React.createRef());
        });

        this.setState({ isLoading: false, domande, refs });
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval(this.countDownInterval);
    }

    async startTimer() {

        this.countDownInterval = setInterval(() => {
            
            if (countDown <= 0) this.endTest();

            var minutes = Math.floor(countDown / 60000);
            var seconds = ((countDown % 60000) / 1000).toFixed(0);
            
            countDown -= 1000;
            this.showTimer(minutes + ":" + (seconds < 10 ? '0' : '') + seconds);
        }, 1000);
    }

    async endTest() {

        this.setState({ inConsegna: true });
        clearInterval(this.countDownInterval);

        var risposte = [];

        this.state.refs.forEach((r, ind) => {
            let tipo = this.state.domande[ind].tipo;
            let id = this.state.domande[ind].id;
            let risp = r.current.getRisposta();

            var risposta = {
                tipo: tipo,
                id: id,
                risposta: null
            };

            if (risp !== null) {
                if (!['2', '3', '6', '7'].includes(tipo)) {
                    risposta.risposta = risp;
                } else {
                    risposta.risposta = JSON.stringify(risp);
                }
            }

            risposte.push(risposta);
        });

        let username = cookies.get('username');
        let token = cookies.get('token');
        let res = await correggiTest(username, token, cookies.get('idTest'), countDown >= 0 ? (1200000 - countDown)/1000 : 1200, JSON.stringify(risposte));
        if (res.result === 'ok') {
            this.setState({ correctionDone: true });
        }

    }

    showTimer(timer) {
        document.getElementById('timer').innerHTML = timer;
    }

    render() {

        if (this.state.isLoading) return <WaveLoading />

        return(
            <div className='dashboard-container' style={{ marginBottom: 200 }}>
                <h3 id="timer" style={{ position: 'fixed', top: 35, left: 0, width: '100vw', textAlign: 'center', color: 'red', fontWeight: 'bold', zIndex: 1000000000 }}>{this.state.timer}</h3>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <section style={{ width: '100%' }}>
                        <h1>{cookies.get('currentDescrizione')}</h1>
                        <p>Rispondi alle seguenti domande e clicca poi su "CONSEGNA"</p>
                    </section>
                </div>
                { this.state.domande.map((d, ind) => {

                    if (d.tipo === '0') return <VeroFalso key={d.id} ref={this.state.refs[ind]} domanda={d.domanda} allegato={d.allegato} />;
                    if (d.tipo === '1') return <SceltaMultipla key={d.id} ref={this.state.refs[ind]} domanda={d.domanda} risposte={JSON.parse(d.risposte)} allegato={d.allegato} />;
                    if (d.tipo === '2') return <TestoTesto key={d.id} ref={this.state.refs[ind]} testi={JSON.parse(d.domanda)} risposte={JSON.parse(d.risposte)} />;
                    if (d.tipo === '3') return <TestoImmagine key={d.id} ref={this.state.refs[ind]} testi={JSON.parse(d.domanda)} risposte={JSON.parse(d.risposte)} />;
                    if (d.tipo === '4') return <AudioVeroFalso key={d.id} ref={this.state.refs[ind]} domanda={d.domanda} audio={d.audio} />;
                    if (d.tipo === '5') return <AudioSceltaMultipla key={d.id} ref={this.state.refs[ind]} domanda={d.domanda} audio={d.audio} risposte={JSON.parse(d.risposte)} />
                    if (d.tipo === '6') return <Cloze key={d.id} ref={this.state.refs[ind]} testo={d.domanda} parole={JSON.parse(d.risposte)} />;
                    if (d.tipo === '7') return <RiorganizzazioneConcettuale key={d.id} ref={this.state.refs[ind]} testo={d.domanda} risposte={JSON.parse(d.risposte)} />;
                    if (d.tipo === '8') return <Completamento key={d.id} ref={this.state.refs[ind]} domanda={d.domanda} risposte={JSON.parse(d.risposte)} />;
                })}

                <div style={{ marginTop: 40, width: 200, marginLeft: 'calc((100% - 200px)/2)' }}>
                    <Button button={"CONSEGNA TEST"} onClick={() => {
                        if (window.confirm("Sei davvero sicuro/a di voler consegnare questo test?")) {
                            this.endTest();
                        }
                    }}/>
                </div>

                { this.state.inConsegna && (
                    <ConsegnaTest result={this.state.correctionDone} idTest={cookies.get('idTest')} />
                )}
            </div>
        );
    }
}