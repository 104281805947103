import React from 'react';
import arrayShuffle from 'array-shuffle';

export default class Cloze extends React.Component {
    constructor(props) {
        super(props);

        var p =  arrayShuffle(props.parole);
        var r = [];
        p.forEach((parola, ind ) => {
            p[ind] = { value: parola.id, label: parola.risposta };
            r.push({  id: undefined, risposta: '' });
        });

        this.state = {
            testo: props.testo, 
            parole: p, 
            risposte: r,
            chunks: [], 
        };
    }

    componentDidMount() {

        var t = this.state.testo;
        var chunks = t.split('%%');
        this.setState({ chunks });
    }

    getRisposta() {

        var completed = true;
        var risposte = [];

        this.state.risposte.map((r) => {
            if (!r.id) completed = false;
            risposte.push(r.id);
        });
        
        if (completed) 
            return risposte;
        else
            return null;
    }

    setRisposta(ind, risposta) {

        let risp = risposta.split(';');
        var r = this.state.risposte;
        r[ind] = { id: parseInt(risp[0]), risposta: risp[1] };
        this.setState({ risposte: r });
    }

    render() {

        var count = 0;

        return(
            <section className='question-container'>
                <p style={{ color: '#818181', marginTop: 10, fontWeight: 'bold' }}>Leggi il testo qui sotto e seleziona poi dal menù a tendina la parola mancante per ogni gap.</p>
                <p style={{ marginTop: 30 }}><span style={{ color: 'grey' }}>
                    { this.state.chunks.map((t, ind) => {

                        if (t === '!') {

                            let c = count;
                            count++;
                                                        
                            return(
                                <select key={ind} style={{ border: 'none' }} defaultValue={this.state.risposte[c].id} onChange={(e) => this.setRisposta(c, e.target.value)}>
                                    <option></option>
                                    { this.state.parole.map((p) => {

                                        let r = this.state.risposte;
                                        var found = false;
                                        var isThis = false;
                                        r.forEach((risposta, ind) => {
                                            if (risposta.id === p.value) {
                                                found = true;
                                                if (ind === c) {
                                                    isThis = true;
                                                }
                                            }
                                        });

                                        if (!found || isThis) {
                                            return(
                                                <option key={p.value} value={p.value + ';' + p.label}>{p.label}</option>
                                            );
                                        } else
                                            return <></>;
                                    })}
                                </select>
                            );
                        } else {
                            return(
                                <span key={ind}>{t}</span>
                            );
                        }
                    })}
                </span></p>
            </section>
        );
    }
}